import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Animated, View, Image, Easing, ActivityIndicator } from 'react-native';

import { IStep } from '../../interfaces/IStep';
import { CustomText } from '../CustomText';
import { useOnboarding } from '../../context/OnboardingContext';
import { useLayout } from '../../context/LayoutContext';
// @ts-ignore
import { COLORS } from '../../utils/Theme';
import { customAnimatedSequence, format } from '../../utils/Utils';
import { Assets } from '../../utils/Assets';
import { createSubscription } from '../../utils/Api';
import { TRIAL_TYPES } from '../../utils/Pricing';
import { ANALYTICS_EVENTS, trackEvent } from '../../utils/Analytics';

const useStyles = (): any => {
  const { WP, HP } = useLayout();

  return {
    gradient: {
      width: WP(100),
      height: HP(100),
      marginLeft: -20,
    },
    container: {
      height: '100%',
      justifyContent: 'center',
      marginHorizontal: 10,
    },
    buttonContainer: {
      marginTop: 20,
      alignItems: 'center',
      marginBottom: 40,
    },
    button: {
      minWidth: 100,
      width: WP(50),
    },
    title: {},
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    image: {
      maxWidth: 250,
      maxHeight: 250,
    },
    subTitle: {
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'center',
    },
    introContainer: {
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      marginHorizontal: 20,
      marginBottom: 100,
    },
    spiritImage: {
      width: 140,
      height: 51,
      marginBottom: 10,
    },
    textContainer: {
      flex: 1,
      justifyContent: 'center',
      marginHorizontal: 20,
      marginBottom: 50,
    },
  };
};

interface IOtherSliderStepProps {
  step: IStep;
}

const BE_IN_DURATION = 3000;
const FADE_IN_DURATION = 800;
const FADE_OUT_DURATION = 1000;
const BE_OUT_DURATION = 1000;

export const OtherSliderStep: FunctionComponent<IOtherSliderStepProps> = (props) => {
  const styles = useStyles();
  const { step } = props;
  const { gotoNext, comesFromEmail, customer, setSubscription, setSetupIntent } = useOnboarding();

  const [title, setTitle] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(comesFromEmail);
  const [error, setError] = useState<boolean>(false);
  const [animationFinished, setAnimationFinished] = useState<boolean>(false);

  const opacityAnim = useRef(new Animated.Value(0)).current;
  const introOpacityAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (comesFromEmail && !isLoading && animationFinished) {
      onNext();
    }
  }, [isLoading, animationFinished]);

  useEffect(() => {
    const createSub = async () => {
      try {
        const subscription = await createSubscription(customer.id, TRIAL_TYPES.TRIAL_0);
        setSubscription(subscription.data.subscription as any);
        setSetupIntent(subscription.data.setupIntent as any);
        setIsLoading(false);
      } catch (exception) {
        setError(true);
      }
    };

    if (comesFromEmail) {
      createSub();
      setTimeout(() => {
        try {
          trackEvent(ANALYTICS_EVENTS.EMAIL_0_DAY_TRIAL_OPENED);
        } finally {
        }
      }, 200);
    }

    document.body.style.overflow = 'hidden';
    document.body.style.background = `linear-gradient(180deg, ${COLORS.SLIDER_GRADIENT[1]} 0%, ${COLORS.SLIDER_GRADIENT[0]} 100%)`;

    return () => {
      document.body.style.overflow = 'scroll';
      document.body.style.background = 'unset';
    };
  }, []);

  useEffect(() => {
    const animations = [
      ...step.screen.values.map((_value: any, index: number, array: any[]) => {
        if (index === 0) {
          return Animated.sequence([
            Animated.timing(introOpacityAnim, {
              toValue: 1,
              duration: FADE_IN_DURATION,
              useNativeDriver: false,
            }),
            Animated.timing(introOpacityAnim, {
              toValue: 1,
              duration: BE_IN_DURATION / 2,
              useNativeDriver: false,
            }),
            Animated.timing(introOpacityAnim, {
              toValue: 0,
              duration: FADE_OUT_DURATION,
              useNativeDriver: false,
            }),
          ]);
        } else if (index === array.length - 1) {
          return Animated.sequence([
            Animated.timing(opacityAnim, {
              toValue: 0,
              duration: BE_OUT_DURATION,
              useNativeDriver: false,
            }),
            Animated.timing(opacityAnim, {
              toValue: 1,
              duration: FADE_IN_DURATION,
              useNativeDriver: false,
            }),
            Animated.timing(opacityAnim, {
              toValue: 1,
              duration: BE_IN_DURATION,
              useNativeDriver: false,
            }),
          ]);
        } else {
          return Animated.sequence([
            Animated.timing(opacityAnim, {
              toValue: 0,
              duration: BE_OUT_DURATION,
              useNativeDriver: false,
            }),
            Animated.timing(opacityAnim, {
              toValue: 1,
              duration: FADE_IN_DURATION,
              useNativeDriver: false,
              easing: Easing.in(Easing.quad),
            }),
            Animated.timing(opacityAnim, {
              toValue: 1,
              duration: BE_IN_DURATION,
              useNativeDriver: false,
            }),
            Animated.timing(opacityAnim, {
              toValue: 0,
              duration: FADE_OUT_DURATION,
              useNativeDriver: false,
            }),
          ]);
        }
      }),
    ];
    customAnimatedSequence(animations, ({ current }: any) => {
      setTitle(step.screen.values[current].elements[0].value);
    }).start(({ finished }: any) => {
      if (finished) {
        if (!comesFromEmail) {
          onNext();
        } else {
          setAnimationFinished(true);
        }
      }
    });
  }, []);

  const onNext = () => {
    gotoNext();
  };

  return (
    <View style={styles.gradient}>
      <View style={styles.container}>
        {!error && isLoading && animationFinished && (
          <View style={styles.textContainer}>
            <ActivityIndicator color={COLORS.WHITE} size="large" />
          </View>
        )}

        {error && (
          <Animated.View
            style={{
              ...styles.textContainer,
            }}>
            <CustomText bold size={18} extrabold style={styles.title}>
              Something went wrong! Please try again later or contact our customer support
              (support@spirit-horoscope.app)
            </CustomText>
          </Animated.View>
        )}

        {!error && !animationFinished && (
          <>
            <Animated.View
              style={{
                ...styles.textContainer,
                opacity: opacityAnim.interpolate({ outputRange: [0, 1], inputRange: [0, 1] }),
              }}>
              <CustomText bold size={32} extrabold style={styles.title}>
                {format(title, false)}
              </CustomText>
            </Animated.View>
            <Animated.View
              style={{
                ...styles.introContainer,
                opacity: introOpacityAnim.interpolate({ outputRange: [0, 1], inputRange: [0, 1] }),
              }}>
              <Image source={Assets.svgs.general.spirit} style={styles.spiritImage} />
              <CustomText size={22}>{step.screen.values[0].elements[0].value}</CustomText>
            </Animated.View>
          </>
        )}
      </View>
    </View>
  );
};
