import React, { FunctionComponent } from 'react';
import { View, Image } from 'react-native';
import { Assets } from '../../../utils/Assets';
import { COLORS } from '../../../utils/Theme';
import { CustomText } from '../../CustomText';

const useStyles = (): any => {
  return {
    container: {
      width: '100%',
      flexDirection: 'row',
      marginBottom: 10,
    },
    tickImage: {
      width: 24,
      height: 24,
      marginTop: 4,
      marginRight: 4,
      marginBottom: 4,
    },
    content: {
      letterSpacing: 0.5,
    },
    contentWrapper: {
      justifyContent: 'center',
      flex: 1,
      paddingHorizontal: 10,
    },
  };
};

interface IHighlightProps {
  content: string;
}

export const Highlight: FunctionComponent<IHighlightProps> = (props) => {
  const styles = useStyles();
  const { content } = props;

  return (
    <View style={styles.container}>
      <Image source={Assets.svgs.general.doubleTick} style={styles.tickImage} />
      <View style={styles.contentWrapper}>
        <CustomText regular style={styles.content} size={13} color={COLORS.PAYWALL_TEXT_COLOR}>
          {content}
        </CustomText>
      </View>
    </View>
  );
};
