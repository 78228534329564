export const enum TRIAL_TYPES {
  TRIAL_0 = 'trial_0',
  TRIAL_1 = 'trial_1',
  TRIAL_5 = 'trial_5',
  TRIAL_9 = 'trial_9',
  TRIAL_13 = 'trial_13',
}

export const TRIAL_PRICES_USD = {
  [TRIAL_TYPES.TRIAL_0]: '0',
  [TRIAL_TYPES.TRIAL_1]: '1',
  [TRIAL_TYPES.TRIAL_5]: '5',
  [TRIAL_TYPES.TRIAL_9]: '9',
  [TRIAL_TYPES.TRIAL_13]: '13.21',
};

export const getTrialPrice = (type: TRIAL_TYPES) => {
  if (!type) {
    return undefined;
  }

  return TRIAL_PRICES_USD[type];
};
