import React, { FunctionComponent } from 'react';
import { View, Image } from 'react-native';
import Lottie from 'react-lottie';

import { IStep } from '../../interfaces/IStep';
import { Button, EBUTTONTYPE } from '../Button';
import { CustomText } from '../CustomText';
import { useOnboarding } from '../../context/OnboardingContext';
import { useLayout } from '../../context/LayoutContext';
import { format } from '../../utils/Utils';

const useStyles = (): any => {
  const { WP } = useLayout();

  return {
    container: {
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    buttonContainer: {
      marginTop: 20,
      alignItems: 'center',
      marginBottom: 40,
      flexDirection: 'row',
    },
    button: {
      minWidth: 100,
      width: WP(35),
    },
    title: {
      textAlign: 'center',
    },
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    image: {
      maxWidth: 250,
      maxHeight: 250,
    },
    subTitle: {
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'center',
    },
    leftButton: {
      marginRight: 5,
    },
    rightButton: {
      marginLeft: 5,
    },
  };
};

interface IFormInfoStepProps {
  step: IStep;
}

export const FormInfoStep: FunctionComponent<IFormInfoStepProps> = (props) => {
  const styles = useStyles();
  const { step } = props;
  const { gotoNext, goBack } = useOnboarding();

  const onNext = () => {
    gotoNext();
  };

  const onBack = () => {
    goBack();
  };

  return (
    <View style={styles.container}>
      {step.screen.animated ? (
        <Lottie
          style={styles.animation}
          options={{ loop: step.screen.loop === true, animationData: step.screen.mediaUrl }}
        />
      ) : (
        <Image style={styles.image} source={step.screen.mediaUrl} />
      )}
      <CustomText style={styles.title} bold size={15}>
        {format(step.screen.title)}
      </CustomText>
      <CustomText style={styles.subTitle} medium size={15}>
        {format(step.screen.subtitle)}
      </CustomText>

      <View style={styles.buttonContainer}>
        <Button
          type={EBUTTONTYPE.SECONDARY}
          style={[styles.button, styles.leftButton]}
          title={'Back'}
          onPress={onBack}
        />
        <Button style={[styles.button, styles.rightButton]} title={'Next'} onPress={onNext} />
      </View>
    </View>
  );
};
