export enum EStepType {
  WELCOME = 'welcome',
  FORM = 'form',
  BUILDING_GUIDANCE = 'building-guidance',
  OTHER = 'other',
  SUBSCRIPTION_PLAN = 'subscription-plan',
  SUBSCRIPTION_INFO = 'subscription-info',
  PAYMENT_UPDATOR = 'payment-updator',
  SKIP_TRIAL = 'skip-trial',
  DOWNLOAD = 'download',
}
