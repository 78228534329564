import React, { FunctionComponent, useEffect } from 'react';
import { View } from 'react-native';

import { useOnboarding } from '../../context/OnboardingContext';
import { Background } from '../Background';
import { ProgressHeader } from './progress/ProgressHeader';
import { Step } from './Step';

const useStyles = (): any => {
  return {
    container: { marginHorizontal: 20, height: '100%' },
    content: { flex: 1 },
  };
};

interface IOnboardingProps {}

export const Onboarding: FunctionComponent<IOnboardingProps> = () => {
  const styles = useStyles();
  const { getCurrentStepExtended, goBack } = useOnboarding();
  const stepExtended = getCurrentStepExtended();

  const onBackButtonEvent = (event: any) => {
    event.preventDefault();
    goBack();
  };

  useEffect(() => {
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [onBackButtonEvent]);

  return (
    <Background>
      <View style={styles.container}>
        <ProgressHeader stepExtended={stepExtended} />
        <View style={styles.content}>
          <Step step={stepExtended.step} />
        </View>
      </View>
    </Background>
  );
};
