import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';

import { useLayout } from '../../context/LayoutContext';
import { useOnboarding } from '../../context/OnboardingContext';
import { IStep } from '../../interfaces/IStep';
import { Assets } from '../../utils/Assets';
import { format } from '../../utils/Utils';
import { Button } from '../Button';
import { InputButton } from '../InputButton';
import { QuestionInput } from '../QuestionInput';

const useStyles = (): any => {
  const { WP } = useLayout();

  return {
    buttonContainer: {
      marginTop: 20,
      alignItems: 'center',
      marginBottom: 40,
    },
    button: {
      minWidth: 100,
      width: WP(50),
    },
    noneContainer: { marginTop: 15 },
    container: { marginBottom: 10 },
  };
};

interface IFormPollStepProps {
  step: IStep;
}

export const FormPollStep: FunctionComponent<IFormPollStepProps> = (props) => {
  const styles = useStyles();
  const { setHeaderVisible } = useOnboarding();
  const { step } = props;
  const [noneActive, setNoneActive] = useState<boolean>(false);

  useEffect(() => {
    setActiveAnswers([]);
    setNoneActive(false);

    if (step.screen.progress === false) {
      setHeaderVisible(false);
    } else {
      setHeaderVisible(true);
    }
  }, [step]);

  const { gotoNext } = useOnboarding();
  const [activeAnswers, setActiveAnswers] = useState<number[]>([]);

  const onSubmit = (answers: any) => {
    setActiveAnswers(answers);
    setNoneActive(false);

    if (!step.screen.multiple) {
      setTimeout(() => {
        gotoNext(answers);
      }, 200);
    }
  };

  const onNext = () => {
    gotoNext(activeAnswers);
  };

  const getAnswers = () => {
    if (step.screen.allowAll) {
      return [...step.screen.values, 'Both'];
    }
    return step.screen.values;
  };

  const onNone = () => {
    setActiveAnswers([]);
    setNoneActive(true);
  };

  return (
    <View style={styles.container}>
      <QuestionInput
        multipleChoice={step.screen.multiple}
        activeAnswers={activeAnswers}
        onSubmit={onSubmit}
        answers={getAnswers()}
        title={format(step.screen.title)}
        subtitle={step.screen.subtitle}
      />
      {step.screen.multiple && (
        <View style={styles.noneContainer}>
          <InputButton
            title={'None'}
            rightIcon={Assets.svgs.general.check2}
            onPress={onNone}
            active={noneActive}
          />
        </View>
      )}

      {step.screen.multiple && step.screen.buttonText && (
        <View style={styles.buttonContainer}>
          <Button
            style={styles.button}
            disabled={activeAnswers.length === 0 && !noneActive}
            title={step.screen.buttonText}
            onPress={onNext}
          />
        </View>
      )}
    </View>
  );
};
