import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Animated, View, Image } from 'react-native';

import { IStep } from '../../interfaces/IStep';
import { CustomText } from '../CustomText';
import { useOnboarding } from '../../context/OnboardingContext';
import { useLayout } from '../../context/LayoutContext';
import { format, ue } from '../../utils/Utils';

import { LineProgress } from './BuildingGuidanceStep/LineProgress';
import { BUILDING_GUIDANCE_LABEL } from '../../utils/Constants';
import { Assets } from '../../utils/Assets';
import { Check } from './BuildingGuidanceStep/Check';
import { COLORS } from '../../utils/Theme';

const useStyles = (): any => {
  const { WP } = useLayout();

  return {
    container: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 10,
    },
    buttonContainer: {
      marginTop: 20,
      alignItems: 'center',
      marginBottom: 40,
    },
    button: {
      minWidth: 100,
      width: WP(50),
    },
    title: {
      textAlign: 'center',
      marginTop: 8,
    },
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    image: {
      maxWidth: 250,
      maxHeight: 250,
    },
    subTitle: {
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'center',
    },
    progressContainer: { width: '100%', marginBottom: 30 },
    spiritImage: {
      width: 140,
      height: 51,
      marginBottom: 10,
    },
    checkContainer: {
      flexDirection: 'row',
    },
    titleContainer: {
      minHeight: 70,
    },
  };
};

interface IBuildingGuidanceStepProps {
  step: IStep;
}

const DURATION = 2000;

export const BuildingGuidanceStep: FunctionComponent<IBuildingGuidanceStepProps> = (props) => {
  const styles = useStyles();
  const { step } = props;
  const { gotoNext, setHeaderVisible } = useOnboarding();

  useEffect(() => {
    setHeaderVisible(false);
  }, [step]);

  // eslint-disable-next-line no-undef
  const relStatus = parseInt(localStorage.getItem(ue.RELATIONSHIP_STATUS) || '0', 10);

  const labels = BUILDING_GUIDANCE_LABEL[relStatus];

  const progress1Anim = useRef(new Animated.Value(0)).current;
  const [progess1, setProgress1] = useState<number>(0);

  const progress2Anim = useRef(new Animated.Value(0)).current;
  const [progess2, setProgress2] = useState<number>(0);

  const progress3Anim = useRef(new Animated.Value(0)).current;
  const [progess3, setProgress3] = useState<number>(0);

  const [loadingStatus, setLoadingStatus] = useState<number>(0);

  const [title, setTitle] = useState<string>('');

  const progressValues = step.screen.values;

  useEffect(() => {
    progress1Anim.addListener((event) => {
      setProgress1(event.value);
      const keys = Object.keys(progressValues[0].progress).map((key: string) => parseInt(key, 10));
      let currentTitle = progressValues[0].progress['0'];
      keys.forEach((key) => {
        if (event.value > key) {
          currentTitle = progressValues[0].progress[key];
        }
      });

      setTitle(currentTitle);
    });

    progress2Anim.addListener((event) => {
      setProgress2(event.value);
      setLoadingStatus(1);
      const keys = Object.keys(progressValues[1].progress).map((key: string) => parseInt(key, 10));
      let currentTitle = progressValues[1].progress['1'];
      keys.forEach((key) => {
        if (event.value > key) {
          if (typeof progressValues[1].progress[key] === 'string') {
            currentTitle = progressValues[1].progress[key];
          } else {
            currentTitle = progressValues[1].progress[key][relStatus].text;
          }
        }
      });

      setTitle(currentTitle);
    });

    progress3Anim.addListener((event) => {
      setProgress3(event.value);
      setLoadingStatus(2);
      const keys = Object.keys(progressValues[2].progress).map((key: string) => parseInt(key, 10));
      let currentTitle = progressValues[2].progress['2'];
      keys.forEach((key) => {
        if (event.value > key) {
          currentTitle = progressValues[2].progress[key];
        }
      });

      setTitle(currentTitle);
    });
  }, []);

  useEffect(() => {
    Animated.sequence([
      ...progressValues[0].accel.map((accel: any) =>
        Animated.timing(progress1Anim, {
          toValue: accel.range[1],
          duration: DURATION,
          useNativeDriver: false,
        }),
      ),
      ...progressValues[1].accel.map((accel: any) =>
        Animated.timing(progress2Anim, {
          toValue: accel.range[1],
          duration: DURATION,
          useNativeDriver: false,
        }),
      ),
      ...progressValues[2].accel.map((accel: any) =>
        Animated.timing(progress3Anim, {
          toValue: accel.range[1],
          duration: DURATION,
          useNativeDriver: false,
        }),
      ),
    ]).start(({ finished }) => {
      if (finished) {
        setLoadingStatus(3);
        setTimeout(() => {
          onNext();
        }, DURATION / 2);
      }
    });
  }, [progress1Anim, progress2Anim, progress3Anim]);

  const onNext = () => {
    gotoNext();
  };

  return (
    <View style={styles.container}>
      <Image source={Assets.svgs.general.spirit} style={styles.spiritImage} />
      <View style={styles.titleContainer}>
        <CustomText style={styles.title} semibold size={15}>
          {title}
        </CustomText>
      </View>
      <View style={styles.progressContainer}>
        <LineProgress strokeColor={COLORS.GROUP_1} label={labels[1]} progress={progess1} />
        <LineProgress strokeColor={COLORS.GROUP_2} label={labels[2]} progress={progess2} />
        <LineProgress strokeColor={COLORS.GROUP_3} label={labels[3]} progress={progess3} />
      </View>
      <View style={styles.checkContainer}>
        <Check isChecked={loadingStatus > 0} checkColor={COLORS.GROUP_1} />
        <Check isChecked={loadingStatus > 1} checkColor={COLORS.GROUP_2} />
        <Check isChecked={loadingStatus > 2} checkColor={COLORS.GROUP_3} />
      </View>
      <CustomText style={styles.title} size={12}>
        {format(step.screen.title)}
      </CustomText>
      <CustomText style={styles.subTitle} size={12}>
        {step.screen.subtitle}
      </CustomText>
    </View>
  );
};
