import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Line } from 'rc-progress';
import { Check } from '@material-ui/icons';

import { CustomText } from '../../CustomText';
import { COLORS } from '../../../utils/Theme';
import { ProgressCircle } from './ProgressCircle';

const useStyles = (): any => {
  return {
    full: {
      flex: 1,
    },
    icon: {
      fill: COLORS.WHITE,
      width: 10,
      height: 10,
    },
    line: { marginLeft: -3, marginRight: -3 },
  };
};

interface IProgressStepProps {
  strokeWidth: number;
  circleSize: number;

  group: number;
  stepExtended: any;
  primaryColor: string;
  secondaryColor: string;
}

export const ProgressStep: FunctionComponent<IProgressStepProps> = (props) => {
  const styles = useStyles();
  const { strokeWidth, circleSize, stepExtended, group, primaryColor, secondaryColor } = props;

  let currentPercentage = 0;

  if (group < stepExtended.group) {
    currentPercentage = 100;
  } else if (stepExtended.group === group) {
    currentPercentage = (100 / stepExtended.total) * stepExtended.idx;
    if (currentPercentage < 5) {
      currentPercentage = 5;
    }
  } else {
    currentPercentage = 0;
  }

  const finished = currentPercentage === 100;

  return (
    <>
      <View style={styles.full}>
        <Line
          percent={currentPercentage}
          strokeWidth={strokeWidth}
          trailWidth={strokeWidth}
          strokeColor={primaryColor}
          trailColor={secondaryColor}
          strokeLinecap="butt"
          style={styles.line}
        />
      </View>

      <ProgressCircle
        circleSize={circleSize}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        finished={finished}>
        {!finished ? <CustomText size={10}>{group}</CustomText> : <Check style={styles.icon} />}
      </ProgressCircle>
    </>
  );
};
