import React, { FunctionComponent } from 'react';
import { View, Image, ImageSourcePropType } from 'react-native';
import { Assets } from '../../../utils/Assets';
import { COLORS } from '../../../utils/Theme';
import { format } from '../../../utils/Utils';
import { CustomText } from '../../CustomText';

const useStyles = (): any => {
  return {
    container: {
      width: '100%',
      marginBottom: 10,
      backgroundColor: COLORS.REVIEW_BACKGROUND,
      padding: 16,
      borderRadius: 12,
    },
    userImage: {
      width: 32,
      height: 32,
      borderRadius: 22,
    },
    content: {
      letterSpacing: 0.5,
      lineHeight: 20,
      marginTop: 10,
    },
    contentWrapper: {
      justifyContent: 'center',
      flex: 1,
      paddingHorizontal: 12,
    },
    topContainer: {
      flexDirection: 'row',
    },
    timeWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    nameWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    topContentWrapper: {
      flex: 1,
    },
    recommendImage: {
      width: 16,
      height: 16,
      marginLeft: 6,
    },
    dotImage: {
      width: 2,
      height: 2,
      marginHorizontal: 6,
    },
    earthImage: {
      width: 12,
      height: 12,
    },
    recommendsText: {
      lineHeight: 20,
    },
  };
};

export interface IReview {
  name: string;
  avatar: ImageSourcePropType;
  daysAgo: number;
  review: string;
}
interface IReviewProps {
  review: IReview;
}

export const Review: FunctionComponent<IReviewProps> = (props) => {
  const styles = useStyles();
  const { review } = props;

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <Image source={review.avatar} style={styles.userImage} />
        <View style={styles.contentWrapper}>
          <View style={styles.topContentWrapper}>
            <View style={styles.nameWrapper}>
              <CustomText helveticaNeue bold size={15}>
                {review.name}
              </CustomText>
              <Image source={Assets.images.general.recommend} style={styles.recommendImage} />
            </View>
            <CustomText
              helveticaNeue
              regular
              noHTML
              color={COLORS.PAYWALL_TEXT_COLOR}
              size={15}
              style={styles.recommendsText}>
              recommends{' '}
              <CustomText bold helveticaNeue noHTML size={15}>
                Spirit
              </CustomText>
            </CustomText>
            <View style={styles.timeWrapper}>
              <CustomText helveticaNeue regular color={COLORS.PAYWALL_TEXT_COLOR} size={13}>
                {`${review.daysAgo} ${review.daysAgo > 1 ? 'days' : 'day'} ago`}
              </CustomText>
              <Image source={Assets.svgs.general.dot} style={styles.dotImage} />
              <Image source={Assets.images.general.earth} style={styles.earthImage} />
            </View>
          </View>
        </View>
      </View>
      <CustomText regular helveticaNeue style={styles.content} size={15} color={COLORS.WHITE}>
        {format(review.review, false)}
      </CustomText>
    </View>
  );
};
