import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

const useStyles = (props: IProgressCircleProps): any => {
  const { circleSize, finished, primaryColor, secondaryColor } = props;

  return {
    circle: {
      width: circleSize,
      height: circleSize,
      borderRadius: circleSize,
      backgroundColor: finished ? primaryColor : secondaryColor,
      zIndex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

interface IProgressCircleProps {
  circleSize: number;
  primaryColor: string;
  secondaryColor: string;
  finished: boolean;
}

export const ProgressCircle: FunctionComponent<IProgressCircleProps> = (props) => {
  const styles = useStyles(props);
  const { children } = props;

  return <View style={styles.circle}>{children}</View>;
};
