import React, { FunctionComponent, useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';

import { IStep } from '../../interfaces/IStep';
import { useOnboarding } from '../../context/OnboardingContext';
import { useLayout } from '../../context/LayoutContext';
import { updateSubscription } from '../../utils/Api';
import { Assets } from '../../utils/Assets';
import { COLORS } from '../../utils/Theme';
import { CustomText } from '../CustomText';
import { EVENTS } from '../../utils/Facebook';
import { getCookie } from '../../utils/Utils';
import { ANALYTICS_EVENTS, trackEvent } from '../../utils/Analytics';

const useStyles = (): any => {
  const { WP } = useLayout();

  const imageWidth = 100;
  const imageHeight = imageWidth / 2.75;

  return {
    container: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 10,
    },
    buttonContainer: {
      marginTop: 20,
      alignItems: 'flex-end',
      flexDirection: 'row',
    },
    button: {
      height: 55,
      backgroundColor: COLORS.BUY_PLAN,
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 8,
      marginTop: 10,
    },
    title: {
      marginTop: 20,
    },
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    image: {
      maxWidth: 250,
      maxHeight: 250,
    },
    subtitle: {
      marginTop: 10,
      marginBottom: 20,
    },
    spiritImage: {
      width: 140,
      height: 51,
      marginBottom: 10,
      marginTop: 25,
    },
    separatorLine: {
      width: WP(100),
      height: 1,
      backgroundColor: COLORS.SEPARATOR_LINE,
      opacity: 0.5,
    },
    contentContainer: {
      flex: 1,
      alignItems: 'center',
    },
    card: {
      width: WP(42),
      padding: 12,
      borderWidth: 2,
      borderColor: COLORS.SEPARATOR_LINE,
      borderRadius: 12,
    },
    cardActive: {
      borderTopWidth: 30,
      borderColor: COLORS.BUY_PLAN,
      marginLeft: 10,
    },
    textContainer: {
      alignItems: 'center',
    },
    textSectionWrapper: {
      marginTop: 10,
      marginBottom: 10,
      alignItems: 'center',
    },
    discountText: { marginTop: -36, height: 45 },
    header: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    splitLine: {
      width: WP(),
      height: 1,
      backgroundColor: COLORS.PAY_INACTIVE,
    },
    headerImage: {
      width: imageWidth,
      height: imageHeight,
      marginTop: 20,
      marginBottom: 10,
    },
  };
};

interface ISubscriptionSkipTrialStepProps {
  step: IStep;
}

export const SubscriptionSkipTrialStep: FunctionComponent<ISubscriptionSkipTrialStepProps> = () => {
  const styles = useStyles();
  const { gotoNext, subscription, getFBC, getFBP } = useOnboarding();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { customer } = useOnboarding();

  const onNext = () => {
    gotoNext([]);
  };

  const upgradePlan = async () => {
    try {
      const evNumber = Math.random().toString(36).substr(2, 10);
      const eventID = `${EVENTS.SUBSCRIBE}.${evNumber}`;
      const fbp = getFBP();
      const fbc = getFBC();
      await updateSubscription(subscription.id, customer.id, eventID, fbp, fbc);
      trackEvent(ANALYTICS_EVENTS.SUBSCRIBE, { currency: 'USD', value: 19 });
      try {
        fbq(
          'track',
          'Subscribe',
          {
            currency: 'USD',
            value: 19,
          },
          {
            eventID,
          },
        );

        trackEvent(ANALYTICS_EVENTS.SKIPPED_TRIAL);
      } finally {
      }
    } finally {
      setIsLoading(false);
      gotoNext([]);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image style={styles.headerImage} source={Assets.svgs.general.spirit} />
        <View style={styles.splitLine} />
      </View>
      <View style={styles.contentContainer}>
        <CustomText size={18} semibold style={styles.title} textAlign="center">
          Not planning on looking back?
        </CustomText>
        <CustomText noHTML medium size={14} style={styles.subtitle} textAlign="center">
          Get a head start and take{' '}
          <CustomText noHTML color={COLORS.BUY_PLAN} medium size={14}>
            23 % off
          </CustomText>{' '}
          your first 2-week plan by skipping the trial and starting today!
        </CustomText>
        <View style={styles.buttonContainer}>
          <View style={styles.card}>
            <View style={styles.textContainer}>
              <CustomText size={14} bold>
                $19 for
              </CustomText>
              <CustomText size={14} bold>
                2-week plan
              </CustomText>
              <View style={styles.textSectionWrapper}>
                <CustomText size={12} light color={COLORS.PAYWALL_FEATURE_COLOR}>
                  Total savings
                </CustomText>
                <CustomText size={14} bold>
                  $0
                </CustomText>
              </View>
              <View style={styles.textSectionWrapper}>
                <CustomText size={12} light color={COLORS.PAYWALL_FEATURE_COLOR}>
                  7-day trial
                </CustomText>
                <CustomText size={14} bold>
                  yes
                </CustomText>
              </View>
            </View>
            <TouchableOpacity disabled={isLoading} style={styles.button} onPress={onNext}>
              <CustomText color={COLORS.BLACK} medium textAlign="center" size={13}>
                Start trial
              </CustomText>
            </TouchableOpacity>
          </View>
          <View style={[styles.card, styles.cardActive]}>
            <CustomText
              color={COLORS.BLACK}
              size={12}
              medium
              textAlign="center"
              style={styles.discountText}>
              SAVE 23%
            </CustomText>
            <View style={styles.textContainer}>
              <CustomText size={14} bold>
                $14.63 for
              </CustomText>
              <CustomText size={14} bold>
                2-week plan
              </CustomText>
              <View style={styles.textSectionWrapper}>
                <CustomText size={12} light color={COLORS.PAYWALL_FEATURE_COLOR}>
                  Total savings
                </CustomText>
                <CustomText size={14} bold>
                  $4.37
                </CustomText>
              </View>
              <View style={styles.textSectionWrapper}>
                <CustomText size={12} light color={COLORS.PAYWALL_FEATURE_COLOR}>
                  7-day trial
                </CustomText>
                <CustomText size={14} bold>
                  no
                </CustomText>
              </View>
            </View>
            <TouchableOpacity disabled={isLoading} style={styles.button} onPress={upgradePlan}>
              <CustomText color={COLORS.BLACK} textAlign="center" size={13} medium>
                Pay now and skip trial
              </CustomText>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};
