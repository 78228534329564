import * as Font from 'expo-font';

export const loadFonts = () => {
  Font.loadAsync({
    'HelveticaNeue-Bold': require('../../assets/fonts/HelveticaNeue-Bold.ttf'),
    'HelveticaNeue-Regular': require('../../assets/fonts/HelveticaNeue-Regular.ttf'),
  });
};

export const Assets = {
  svgs: {
    general: {
      check2: require('../../assets/images/general/check2.svg'),
      intro: require('../../assets/images/general/intro.svg'),
      spirit: require('../../assets/images/general/spirit.svg'),
      close: require('../../assets/images/general/close.svg'),
      backgroundStars: require('../../assets/images/general/backgroundStars.svg'),
      doubleTick: require('../../assets/images/general/doubleTick.svg'),
      dot: require('../../assets/images/general/dot.svg'),
      star: require('../../assets/images/general/star.svg'),
      mountains: require('../../assets/images/general/mountains.svg'),
      appleLogo: require('../../assets/images/general/appleLogo.svg'),
      laurelBush: require('../../assets/images/general/laurelBush.svg'),
    },
    cc: {
      brands: require('../../assets/images/cc/brands.svg'),
      cc: require('../../assets/images/cc/cc.svg'),
    },
  },
  images: {
    onboarding: {
      couple: {
        age_20: require('../../assets/images/onboarding/couple/age_20.jpg'),
        age_30: require('../../assets/images/onboarding/couple/age_30.jpg'),
        age_40: require('../../assets/images/onboarding/couple/age_40.jpg'),
        age_50: require('../../assets/images/onboarding/couple/age_50.jpg'),
      },
      reviewAvatar: {
        aaron: require('../../assets/images/onboarding/reviewAvatar/aaron.png'),
        bob: require('../../assets/images/onboarding/reviewAvatar/bob.png'),
        britney: require('../../assets/images/onboarding/reviewAvatar/britney.png'),
        dick: require('../../assets/images/onboarding/reviewAvatar/dick.png'),
        evan: require('../../assets/images/onboarding/reviewAvatar/evan.png'),
        jack: require('../../assets/images/onboarding/reviewAvatar/jack.png'),
        jessica: require('../../assets/images/onboarding/reviewAvatar/jessica.png'),
        johanna: require('../../assets/images/onboarding/reviewAvatar/johanna.png'),
        justin: require('../../assets/images/onboarding/reviewAvatar/justin.png'),
        kevin: require('../../assets/images/onboarding/reviewAvatar/kevin.png'),
        kristen: require('../../assets/images/onboarding/reviewAvatar/kristen.png'),
        lamarr: require('../../assets/images/onboarding/reviewAvatar/lamarr.png'),
        latisha: require('../../assets/images/onboarding/reviewAvatar/latisha.png'),
        liz: require('../../assets/images/onboarding/reviewAvatar/liz.png'),
        marie: require('../../assets/images/onboarding/reviewAvatar/marie.png'),
        marina: require('../../assets/images/onboarding/reviewAvatar/marina.png'),
        ned: require('../../assets/images/onboarding/reviewAvatar/ned.png'),
        sean: require('../../assets/images/onboarding/reviewAvatar/sean.png'),
        shauna: require('../../assets/images/onboarding/reviewAvatar/shauna.png'),
        yolanda: require('../../assets/images/onboarding/reviewAvatar/yolanda.png'),
      },
    },
    general: {
      user: require('../../assets/images/general/user.jpg'),
      recommend: require('../../assets/images/general/recommend.png'),
      earth: require('../../assets/images/general/earth.png'),
      email: require('../../assets/images/general/email.png'),
      forbes: require('../../assets/images/general/forbes.png'),
      mashable: require('../../assets/images/general/mashable.png'),
      techcrunch: require('../../assets/images/general/techcrunch.png'),
    },
  },
  animations: {
    arrowsDown: require('../../assets/animations/arrows_down.json'),
    loadingMoon: require('../../assets/animations/loadingMoon.json'),
    check: require('../../assets/animations/check.json'),
    clock: require('../../assets/animations/clock.json'),
    shootingStar: require('../../assets/animations/shooting-star.json'),
    natalChart: require('../../assets/animations/natal-chart.json'),
    star: require('../../assets/animations/star.json'),
    key: require('../../assets/animations/key.json'),
    umbrella: require('../../assets/animations/umbrella.json'),
    sun: require('../../assets/animations/sun.json'),
    magnifyingGlass: require('../../assets/animations/magnifying-glass.json'),
    archery: require('../../assets/animations/archery.json'),
    clouds: require('../../assets/animations/clouds.json'),
    heart: require('../../assets/animations/heart.json'),
    head: require('../../assets/animations/head.json'),
    heartHead: require('../../assets/animations/heart-head.json'),
    compass: require('../../assets/animations/compass.json'),
  },
  files: {},
};
