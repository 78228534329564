import { Line } from 'rc-progress';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { COLORS } from '../../../utils/Theme';
import { CustomText } from '../../CustomText';

const useStyles = (): any => {
  return {
    labelContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 8,
    },
    container: {
      marginVertical: 12,
    },
  };
};

interface ILineProgressProps {
  progress: number;
  strokeColor: string;
  label: string;
}

export const LineProgress: FunctionComponent<ILineProgressProps> = (props) => {
  const styles = useStyles();

  const { progress, strokeColor, label } = props;

  return (
    <View style={styles.container}>
      <View style={styles.labelContainer}>
        <CustomText size={15} bold={progress > 0 && progress < 100} color={strokeColor}>
          {label}
        </CustomText>
        <CustomText
          size={15}
          bold={progress > 0 && progress < 100}
          color={strokeColor}>{`${Math.floor(progress)} %`}</CustomText>
      </View>
      <Line
        percent={progress}
        strokeWidth={3}
        trailWidth={3}
        strokeColor={strokeColor}
        trailColor={COLORS.PROGRESS_BAR_BACKGROUND}
      />
    </View>
  );
};
