import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { makeStyles, Theme } from '@material-ui/core';
import { COLORS } from '../../../utils/Theme';

const useStyles = (): any => {
  return {
    dot: {
      backgroundColor: COLORS.PROGRESS_BAR_BACKGROUND,
      width: '35%',
      height: '35%',
      borderRadius: 13,
    },
    container: {
      width: 25,
      height: 25,
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: 10,
      marginHorizontal: 2,
    },
  };
};

interface StyleProps {
  color: string;
}

const useMuiStyles = makeStyles<Theme, StyleProps>({
  root: {
    color: (props) => props.color,
    width: 20,
    height: 20,
  },
});

interface ICheckProps {
  isChecked: boolean;
  checkColor: string;
}

export const Check: FunctionComponent<ICheckProps> = (props) => {
  const styles = useStyles();
  const { isChecked, checkColor } = props;
  const muiStyles = useMuiStyles({ color: checkColor });

  return (
    <View style={styles.container}>
      {isChecked ? (
        <CheckIcon style={styles.image} classes={muiStyles} />
      ) : (
        <View style={styles.dot} />
      )}
    </View>
  );
};
