import mixpanel from 'mixpanel-browser';
import * as qs from 'qs';
import { useEffect, useState } from 'react';
import { useOnboarding } from '../context/OnboardingContext';

import { ANALYTICS_EVENTS, iOSversion, trackEvent } from '../utils/Analytics';
import { getCookie } from '../utils/Utils';

export const useRedirect = () => {
  const [isWeb, setWebView] = useState(false);
  const { mixpanelAnon, searchParams } = useOnboarding();
  const [count, setCount] = useState(0);
  const userAgent =
    typeof window.navigator === 'undefined' ? '' : navigator.userAgent.toLowerCase();
  const { redirected } = qs.parse(window.location.search);

  const handleCheckWebView = () => {
    // @ts-ignore
    const standalone = window.navigator?.standalone;
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    const isInFacebook = userAgent.indexOf('fban') > -1 || userAgent.indexOf('fbav') > -1;
    const isInInstagram = userAgent.includes('wv') && userAgent.includes('instagram');

    if (ios) {
      if (!standalone && safari) {
        return false;
      }

      if (!standalone && !safari) {
        if (isInFacebook && count === 1) {
          return false;
        } else {
          return true;
        }
      }
    }

    if (isInInstagram) {
      return !redirected;
    }

    if (isInFacebook) {
      return false;
    }

    return false;
  };

  const handleRedirectToBrowser = () => {
    if (iOSversion() >= 15) {
      return;
    }

    try {
      mixpanel.track(ANALYTICS_EVENTS.REDIRECTING);
    } catch (error) {
      console.log(error);
    }

    const { search, hostname } = window.location;
    const query = `secondPage=true&domain=${hostname}&redirected=true&anon=${mixpanelAnon}&_fbp=${getCookie(
      '_fbp',
    )}&_fbc=${getCookie('_fbc')}`;

    const link = document.createElement('a');
    let querySearch = search ? search + '&' + query : '?' + query;

    const keys = Object.keys(searchParams);
    if (keys.length > 0) {
      keys.forEach((key) => {
        querySearch += `&${key}=${searchParams[key]}`;
      });
    }

    link.href = `ftp://files.spirit-horoscope.app/redirect.html${querySearch}`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    setWebView(handleCheckWebView());
  }, []);

  return {
    isWeb,
    count,
    setCount,
    handleRedirectToBrowser,
  };
};
