import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';

import { IStep } from '../../interfaces/IStep';
import { Button } from '../Button';
import { CustomText } from '../CustomText';
import { useOnboarding } from '../../context/OnboardingContext';
import { useLayout } from '../../context/LayoutContext';
import { format } from '../../utils/Utils';
import moment from 'moment';
import { CustomDateSelect } from './CustomDateSelect';
import { getDayOptions, getMonthsOptions, getYearOptions } from '../../utils/SelectHelper';

const useStyles = (): any => {
  const { WP } = useLayout();

  return {
    container: {
      height: '100%',
      alignItems: 'center',
      marginHorizontal: 10,
    },
    buttonContainer: {
      marginTop: 20,
      alignItems: 'center',
      marginBottom: 40,
    },
    button: {
      minWidth: 100,
      width: WP(50),
    },
    title: {
      textAlign: 'center',
    },
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    image: {
      maxWidth: 250,
      maxHeight: 250,
    },
    subTitle: {
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'center',
    },
  };
};

interface IFormDateStepProps {
  step: IStep;
}

export const FormDateStep: FunctionComponent<IFormDateStepProps> = (props) => {
  const styles = useStyles();

  const { step } = props;
  const { gotoNext } = useOnboarding();

  const [month, setMonth] = useState<number>(-1);
  const [day, setDay] = useState<number>(-1);
  const [year, setYear] = useState<number>(-1);

  const [daysInMonth, setDaysInMonth] = useState<number>();

  const onNext = () => {
    const currentDate = moment.utc(`${year}-${month}-${day}`, 'YYYY-MM-DD');
    gotoNext([currentDate.utc().valueOf()]);
  };

  useEffect(() => {
    if (month) {
      let yearToUse = moment().year();
      if (year) {
        yearToUse = year;
      }
      const currentDaysInMonth = moment(`${yearToUse}-${month}`, 'YYYY-MM').daysInMonth();
      setDaysInMonth(currentDaysInMonth);

      if (day && day > currentDaysInMonth) {
        setDay(currentDaysInMonth);
      }
    }
  }, [month, year]);

  return (
    <View style={styles.container}>
      <CustomText style={styles.title} bold size={15}>
        {format(step.screen.title)}
      </CustomText>
      <CustomText style={styles.subTitle} medium size={15}>
        {step.screen.subtitle}
      </CustomText>
      <CustomDateSelect
        label="Month"
        options={getMonthsOptions()}
        selected={month}
        onChange={setMonth}
      />
      <CustomDateSelect
        label="Day"
        // @ts-ignore
        options={getDayOptions(daysInMonth)}
        selected={day}
        onChange={setDay}
      />
      <CustomDateSelect
        label="Year"
        options={getYearOptions()}
        selected={year}
        onChange={setYear}
      />
      <View style={styles.buttonContainer}>
        <Button
          style={styles.button}
          disabled={!(year !== -1 && month !== -1 && day !== -1)}
          title={'Next'}
          onPress={onNext}
        />
      </View>
    </View>
  );
};
