import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';

import { useLayout } from '../../context/LayoutContext';
import { useOnboarding } from '../../context/OnboardingContext';
import { IStep } from '../../interfaces/IStep';
import { format } from '../../utils/Utils';
import { Button } from '../Button';
import { CustomText } from '../CustomText';
// @ts-ignore
// import { GOOGLE_API_KEY } from '@env';
import { usePlacesWidget } from 'react-google-autocomplete';
import { CustomTextField } from './CustomTextField';

const GOOGLE_API_KEY = '1234';

const useStyles = (): any => {
  const { WP } = useLayout();

  return {
    container: {
      height: '100%',
      alignItems: 'center',
      marginHorizontal: 10,
    },
    buttonContainer: {
      marginTop: 20,
      alignItems: 'center',
      marginBottom: 40,
    },
    button: {
      minWidth: 100,
      width: WP(50),
    },
    title: {
      textAlign: 'center',
    },
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    image: {
      maxWidth: 250,
      maxHeight: 250,
    },
    subTitle: {
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'center',
    },
  };
};

interface IFormPlaceStepProps {
  step: IStep;
}

export const FormPlaceStep: FunctionComponent<IFormPlaceStepProps> = (props) => {
  const styles = useStyles();
  const { step } = props;
  const { gotoNext } = useOnboarding();
  const [place, setPlace] = useState<{ address: string; placeID: string }>();
  const [inputText, setInputText] = useState<string>('');

  const { ref: materialRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: (selectedPlace: any) => {
      setInputText(selectedPlace.formatted_address);
      setPlace({ address: selectedPlace.formatted_address, placeID: selectedPlace.place_id });
    },
    options: {
      fields: ['place_id'],
    },
  });

  const onUserChange = (newValue: string) => {
    setPlace(undefined);
    setInputText(newValue);
  };

  const onNext = () => {
    gotoNext([JSON.stringify(place)]);
  };

  return (
    <View style={styles.container}>
      <CustomText style={styles.title} bold size={15}>
        {format(step.screen.title)}
      </CustomText>
      <CustomText style={styles.subTitle} medium size={15}>
        {step.screen.subtitle}
      </CustomText>
      <CustomTextField
        ref={materialRef}
        error={!place && inputText !== ''}
        value={inputText}
        onValueChange={onUserChange}
      />
      <View style={styles.buttonContainer}>
        <Button style={styles.button} title={'Next'} onPress={onNext} />
      </View>
    </View>
  );
};
