import React, { FunctionComponent, useEffect, useState } from 'react';
import { View, Image } from 'react-native';

import { IStep } from '../../interfaces/IStep';
import { Button, EBUTTONTYPE } from '../Button';
import { CustomText } from '../CustomText';
import { useOnboarding } from '../../context/OnboardingContext';
import { useLayout } from '../../context/LayoutContext';
import { format, calculateAge } from '../../utils/Utils';
import { Assets } from '../../utils/Assets';
import { COLORS } from '../../utils/Theme';

const useStyles = (): any => {
  const { WP } = useLayout();

  return {
    container: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 10,
    },
    button: {
      minWidth: 100,
      width: WP(35),
    },
    title: {
      textAlign: 'center',
      marginBottom: 20,
    },
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    image: {
      width: 150,
      height: 150,
      borderRadius: 75,
      marginBottom: 20,
    },
    subTitleContainer: {
      backgroundColor: COLORS.QUOTE_CARD_BACKGROUND_COLOR,
      borderRadius: 10,
      padding: '3%',
      marginBottom: 20,
    },
    subTitle: {
      textAlign: 'center',
    },
    buttonContainer: {
      marginTop: 20,
      alignItems: 'center',
      marginBottom: 40,
      flexDirection: 'row',
    },
    leftButton: {
      marginRight: 5,
    },
    rightButton: {
      marginLeft: 5,
    },
  };
};

interface IFormSpiritStepProps {
  step: IStep;
}

export const FormSpiritStep: FunctionComponent<IFormSpiritStepProps> = (props) => {
  const styles = useStyles();
  const { step } = props;
  const { gotoNext, goBack } = useOnboarding();

  const [imageUrl, setImageUrl] = useState<string>(Assets.images.onboarding.couple.age_30);

  const onNext = () => {
    gotoNext();
  };

  const onBack = () => {
    goBack();
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const dateString = localStorage.getItem('date-of-birth');
    let ageGroup = calculateAge(parseInt(dateString!, 10));
    if (ageGroup < 20) {
      ageGroup = 20;
    }
    let value = step.screen.values.find((currentValue: any) => {
      if (!currentValue.conditions) {
        return false;
      }
      return currentValue.conditions['age-group'] === ageGroup;
    });
    if (!value) {
      value = step.screen.values.find((currentValue: any) => {
        if (!currentValue.conditions) {
          return true;
        }
        return false;
      });
    }
    setImageUrl(value.mediaUrl);
  }, []);

  return (
    <View style={styles.container}>
      <Image source={{ uri: imageUrl }} style={styles.image} />
      <CustomText style={styles.title} bold size={15}>
        {format(step.screen.title)}
      </CustomText>
      <View style={styles.subTitleContainer}>
        <CustomText light style={styles.subTitle} medium size={14}>
          {step.screen.subtitle}
        </CustomText>
      </View>
      <CustomText style={styles.subTitle} medium size={11}>
        {step.screen.additional}
      </CustomText>
      <View style={styles.buttonContainer}>
        <Button
          type={EBUTTONTYPE.SECONDARY}
          style={[styles.button, styles.leftButton]}
          title={'Back'}
          onPress={onBack}
        />
        <Button style={[styles.button, styles.rightButton]} title={'Next'} onPress={onNext} />
      </View>
    </View>
  );
};
