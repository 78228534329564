import React, { FunctionComponent, useMemo } from 'react';
import { Animated, Text, TextProps } from 'react-native';
import { COLORS } from '../utils/Theme';

export interface ICustomTextProps extends TextProps {
  children?: any;
  italic?: boolean;
  size?: number;
  color?: string;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
  helveticaNeue?: boolean;
  thin?: boolean;
  extralight?: boolean;
  light?: boolean;
  regular?: boolean;
  medium?: boolean;
  semibold?: boolean;
  bold?: boolean;
  extrabold?: boolean;
  black?: boolean;
  ref?: any;
  animated?: boolean;
  noHTML?: boolean;
}

const getFontFamily = (props: ICustomTextProps) => {
  if (props.helveticaNeue) {
    if (props.bold) {
      return 'HelveticaNeue-Bold';
    }

    return 'HelveticaNeue-Regular';
  }

  return 'Poppins';
};

const getFontWeight = (props: ICustomTextProps) => {
  if (props.regular) {
    return 400;
  } else if (props.medium) {
    return 500;
  } else if (props.semibold) {
    return 600;
  } else if (props.bold) {
    return 700;
  }

  return 400;
};

const useStyles = (props: ICustomTextProps): any => {
  const { size = 12, color = COLORS.WHITE, textAlign = 'left' } = props;

  return {
    text: {
      fontSize: size,
      color: color,
      textAlign: textAlign,
    },
  };
};

export const CustomText: FunctionComponent<ICustomTextProps> = React.forwardRef(
  (props: ICustomTextProps, ref: any) => {
    const { children, style, animated, noHTML, ...rest } = props;
    const styles = useStyles(props);

    const fontFamily = useMemo(() => {
      return getFontFamily(props);
    }, [
      props.thin,
      props.extralight,
      props.light,
      props.medium,
      props.semibold,
      props.bold,
      props.extrabold,
      props.black,
    ]);

    const fontWeight = useMemo(() => {
      return getFontWeight(props);
    }, [
      props.thin,
      props.extralight,
      props.light,
      props.medium,
      props.semibold,
      props.bold,
      props.extrabold,
      props.black,
    ]);

    return animated ? (
      <Animated.Text style={[styles.text, { fontFamily, fontWeight }, style]} {...rest} ref={ref}>
        <div dangerouslySetInnerHTML={{ __html: children }} />
      </Animated.Text>
    ) : (
      <Text style={[styles.text, { fontFamily, fontWeight }, style]} {...rest} ref={ref}>
        {noHTML ? children : <div dangerouslySetInnerHTML={{ __html: children }} />}
      </Text>
    );
  },
);

CustomText.defaultProps = {
  // size: CONTENT_FONT_SIZE,
} as Partial<ICustomTextProps>;
