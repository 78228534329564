import React, { FunctionComponent, useEffect, useState } from 'react';
import { View, Image, ActivityIndicator } from 'react-native';
import {
  useStripe,
  useElements,
  CardNumberElement,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import toast from 'react-hot-toast';

import { IStep } from '../../../interfaces/IStep';
import { CustomText } from '../../CustomText';
import { useOnboarding } from '../../../context/OnboardingContext';
import { useLayout } from '../../../context/LayoutContext';
import { updateDefaultPaymentMethod } from '../../../utils/Api';
import { getTrialPrice, TRIAL_TYPES } from '../../../utils/Pricing';
import { Assets } from '../../../utils/Assets';
import { COLORS } from '../../../utils/Theme';
import { SpecialOffer } from './SpecialOffer';
import { CCModal } from './CCModal';
import { PayDisclaimer } from './PayDisclaimer';
import { Button, EBUTTONTYPE } from '../../Button';
import { OrSplitter } from './OrSplitter';
import { EVENTS } from '../../../utils/Facebook';
import { getCookie } from '../../../utils/Utils';
import {
  ANALYTICS_EVENTS,
  EPAYMENTMETHODS,
  trackEvent,
  trackPaymentMethod,
} from '../../../utils/Analytics';

const useStyles = (): any => {
  const { WP } = useLayout();

  const imageWidth = 100;
  const imageHeight = imageWidth / 2.75;

  return {
    image: {
      width: imageWidth,
      height: imageHeight,
      marginTop: 20,
      marginBottom: 10,
    },
    container: {
      height: '100%',
      width: WP(100),
      marginLeft: -20,
    },
    title: {
      textAlign: 'center',
    },
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    subTitle: {
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'center',
    },
    fullWidth: {
      width: WP() - 60,
      marginLeft: 30,
      alignItems: 'center',
    },
    textInput: { color: 'white' },
    header: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    splitLine: {
      width: WP(),
      height: 1,
      backgroundColor: COLORS.PAY_INACTIVE,
    },
    splitLine2: {
      width: '100%',
      height: 1,
      backgroundColor: COLORS.PAY_INACTIVE,
    },
    headerTitle: {
      marginBottom: 20,
    },
    subHeaderTitle: {
      marginBottom: 20,
    },
    splitSection: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginVertical: 18,
    },
    promoCode: {
      marginTop: 8,
      width: '100%',
    },
    priceContainer: {
      flexDirection: 'row',
    },
    inactivePrice: {
      marginRight: 15,
      textDecorationLine: 'line-through',
      textDecorationStyle: 'solid',
    },
    payButtonContainer: { width: '100%', marginTop: 5 },
    icon: {
      marginRight: 8,
      width: 16,
      height: 16,
    },
    splitter: {
      marginVertical: 10,
    },
    activityIndicator: {
      marginTop: 20,
    },
  };
};

interface ISubscriptionPayStepProps {
  step: IStep;
}

export const SubscriptionPayStep: FunctionComponent<ISubscriptionPayStepProps> = () => {
  const styles = useStyles();
  const {
    gotoNext,
    customer,
    subscription,
    setPaymentIntent,
    trialPriceType,
    setupIntent,
    comesFromEmail,
    getFBC,
    getFBP,
  } = useOnboarding();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [name, onNameChange] = useState('');
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState<boolean>(true);
  const [isPaying, setIsPaying] = useState<boolean>(false);

  const price = getTrialPrice(trialPriceType || TRIAL_TYPES.TRIAL_0);

  useEffect(() => {
    if (stripe) {
      if (price === '0') {
        setIsLoadingPaymentMethods(false);
        return;
      }

      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Spirit',
          amount: Number(price) * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment()
        .then((result) => {
          if (result) {
            setPaymentRequest(pr);
            try {
              trackEvent(ANALYTICS_EVENTS.MOBILE_PAY_VISIBLE);
            } finally {
            }
          } else {
            setIsLoadingPaymentMethods(false);
          }
        })
        .catch(() => {
          setIsLoadingPaymentMethods(false);
        });

      pr.on('paymentmethod', async (ev) => {
        const secret = subscription.latest_invoice.payment_intent.client_secret;
        let { paymentIntent: newPaymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            secret,
            { payment_method: ev.paymentMethod.id, setup_future_usage: 'off_session' },
            { handleActions: false },
          );

        if (confirmError) {
          ev.complete('fail');
        } else {
          ev.complete('success');
          if (newPaymentIntent!.status === 'requires_action') {
            const { error } = await stripe.confirmCardPayment(secret);
            if (error) {
            } else {
              const evNumber = Math.random().toString(36).substr(2, 10);
              const eventPurchaseID = `${EVENTS.PURCHASE}.${evNumber}`;
              const eventStartTrialID = `${EVENTS.START_TRIAL}.${evNumber}`;
              const fbp = getFBP();
              const fbc = getFBC();
              updateDefaultPaymentMethod(
                customer.id,
                newPaymentIntent?.payment_method!,
                trialPriceType!,
                eventPurchaseID,
                eventStartTrialID,
                fbp,
                fbc,
              );
              setPaymentIntent(newPaymentIntent!);
              onNext();

              trackPaymentMethod(EPAYMENTMETHODS.MOBILE_PAY);
            }
          } else {
            const evNumber = Math.random().toString(36).substr(2, 10);
            const eventPurchaseID = `${EVENTS.PURCHASE}.${evNumber}`;
            const eventStartTrialID = `${EVENTS.START_TRIAL}.${evNumber}`;
            const fbp = getFBP();
            const fbc = getFBC();
            updateDefaultPaymentMethod(
              customer.id,
              newPaymentIntent?.payment_method!,
              trialPriceType!,
              eventPurchaseID,
              eventStartTrialID,
              fbp,
              fbc,
            );
            setPaymentIntent(newPaymentIntent!);
            onNext();

            trackPaymentMethod(EPAYMENTMETHODS.MOBILE_PAY);
          }
        }
      });
    }
  }, [stripe]);

  if (!stripe || !elements) {
    return null;
  }

  const onNext = () => {
    gotoNext([]);
  };

  const handleSubmit = async (e: any) => {
    try {
      setIsPaying(true);
      if (e) {
        e.preventDefault();
      }

      const cardElement = elements.getElement(CardNumberElement);
      let error, paymentMethod;

      if (setupIntent) {
        const result = await stripe.confirmCardSetup(
          setupIntent.client_secret,
          {
            payment_method: {
              card: cardElement!,
              billing_details: {
                name: name,
              },
            },
          },
          { handleActions: false },
        );
        paymentMethod = result.setupIntent?.payment_method!;
      } else {
        const result = await stripe.confirmCardPayment(
          subscription.latest_invoice.payment_intent.client_secret,
          {
            payment_method: {
              card: cardElement!,
              billing_details: {
                name: name,
              },
            },
            setup_future_usage: 'off_session',
            save_payment_method: true,
          },
        );

        error = result.error;
        paymentMethod = result.paymentIntent?.payment_method!;
      }

      if (!error) {
        const evNumber = Math.random().toString(36).substr(2, 10);
        const eventPurchaseID = `${EVENTS.PURCHASE}.${evNumber}`;
        const eventStartTrialID = `${EVENTS.START_TRIAL}.${evNumber}`;
        const fbp = getFBP();
        const fbc = getFBC();
        updateDefaultPaymentMethod(
          customer.id,
          paymentMethod,
          trialPriceType || TRIAL_TYPES.TRIAL_0,
          eventPurchaseID,
          eventStartTrialID,
          fbp,
          fbc,
        );
        setIsPaying(false);
        try {
          fbq(
            'track',
            'Purchase',
            {
              currency: 'USD',
              value: Number(price),
              content_name: 'trial',
            },
            {
              eventID: eventPurchaseID,
            },
          );
          fbq(
            'track',
            'StartTrial',
            { currency: 'USD', value: 19.0 },
            {
              eventID: eventStartTrialID,
            },
          );

          trackEvent(ANALYTICS_EVENTS.PURCHASE, {
            currency: 'USD',
            value: Number(price),
            content_name: 'trial',
          });
          trackEvent(ANALYTICS_EVENTS.STARTTRIAL, { currency: 'USD', value: 19.0 });

          if (comesFromEmail) {
            trackEvent(ANALYTICS_EVENTS.EMAIL_0_DAY_TRIAL_PURCHASED);
          } else {
            trackPaymentMethod(EPAYMENTMETHODS.CC);
          }
        } finally {
        }
        onNext();
      } else {
        toast.error('Something went wrong!');
        setIsPaying(false);
      }
    } catch (error) {
      toast.error('Something went wrong!');
      setIsPaying(false);
    }
  };

  const onCreditCard = () => {
    setModalOpen(true);
  };

  const onCloseCreditCard = () => {
    setModalOpen(false);
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image style={styles.image} source={Assets.svgs.general.spirit} />
        <View style={styles.splitLine} />
      </View>
      <View style={styles.fullWidth}>
        <SpecialOffer />
        <CustomText style={styles.headerTitle} bold size={18}>
          Start your 7-day trial
        </CustomText>
        <CustomText style={styles.subHeaderTitle} bold size={13}>
          No commitment. Cancel anytime.
        </CustomText>
        <View style={styles.splitLine2} />
        <View style={styles.splitSection}>
          <CustomText size={13} bold>
            Total today
          </CustomText>
          <CustomText color={COLORS.SPECIAL_OFFER} size={13} bold>
            {`$${price}`}
          </CustomText>
        </View>
        <View style={styles.splitLine2} />
        <CustomText size={13} color={COLORS.SPECIAL_OFFER} style={styles.promoCode} semibold>
          Code SPIRIT30 applied!
        </CustomText>
        <View style={styles.splitSection}>
          <View>
            <CustomText size={13} medium>
              Your cost per 2 weeks after trial
            </CustomText>
            <CustomText size={13} medium color={COLORS.PAY_INACTIVE}>
              Save $10 every 2 weeks
            </CustomText>
          </View>
          <View style={styles.priceContainer}>
            <CustomText style={styles.inactivePrice} size={13} color={COLORS.PAY_INACTIVE} bold>
              $29
            </CustomText>
            <CustomText size={13} bold>
              $19
            </CustomText>
          </View>
        </View>
        <View style={styles.splitLine2} />
        <PayDisclaimer />

        {isLoadingPaymentMethods && (
          <ActivityIndicator style={styles.activityIndicator} size={'large'} color={COLORS.WHITE} />
        )}

        <View style={styles.payButtonContainer}>
          {paymentRequest && (
            <PaymentRequestButtonElement
              onReady={() => {
                setIsLoadingPaymentMethods(false);
              }}
              options={{
                paymentRequest,
                style: {
                  paymentRequestButton: {
                    theme: 'light',
                  },
                },
              }}
            />
          )}
          {!isLoadingPaymentMethods && paymentRequest && <OrSplitter style={styles.splitter} />}
          {!isLoadingPaymentMethods && (
            <Button
              buttonHeight={paymentRequest ? 37 : undefined}
              style={styles.button}
              onPress={onCreditCard}
              type={EBUTTONTYPE.SUBMIT}
              title="Credit / Debit Card"
            />
          )}
        </View>

        <CCModal
          modalOpen={modalOpen}
          onCloseCreditCard={onCloseCreditCard}
          handleSubmit={handleSubmit}
          name={name}
          onNameChange={onNameChange}
          loading={isPaying}
        />
      </View>
    </View>
  );
};
