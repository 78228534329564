import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

import { Assets } from '../utils/Assets';
import { COLORS } from '../utils/Theme';
import { CustomText } from './CustomText';
import { InputButton } from './InputButton';

const useStyles = (): any => {
  return {
    buttonsContainer: {
      marginTop: 25,
      justifyContent: 'flex-start',
    },
    marginBottom: {
      marginBottom: 15,
    },
    titleTextStyle: {
      fontSize: 16,
      letterSpacing: 0.15,
      marginTop: 10,
    },
    subtitleTextStyle: {
      fontSize: 18 / 1.5,
      color: COLORS.TEXT_GRAY,
      letterSpacing: 0.15,
      marginTop: 5,
    },
  };
};

interface IQuestionInputProps {
  onSubmit: (answers: number[]) => void;
  activeAnswers: number[];
  title: string;
  answers: string[];
  multipleChoice: boolean;
  subtitle?: string;
}

export const QuestionInput: FunctionComponent<IQuestionInputProps> = (props) => {
  const styles = useStyles();
  const { onSubmit, activeAnswers, title, multipleChoice, subtitle, answers } = props;

  const onPress = (index: number) => {
    if (multipleChoice) {
      if (activeAnswers.findIndex((v) => v === index) !== -1) {
        const newActiveAnswers = activeAnswers.filter((v) => v !== index);
        onSubmit(newActiveAnswers);
      } else {
        onSubmit([...activeAnswers, index]);
      }
    } else {
      onSubmit([index]);
    }
  };

  return (
    <>
      <CustomText bold style={styles.titleTextStyle} textAlign="center">
        {title}
      </CustomText>
      {subtitle && (
        <CustomText style={styles.subtitleTextStyle} textAlign="center">
          {subtitle}
        </CustomText>
      )}
      <View style={styles.buttonsContainer}>
        {answers.map((entry, index) => {
          return (
            <View style={[index < answers.length - 1 && styles.marginBottom]} key={entry}>
              <InputButton
                title={entry}
                rightIcon={Assets.svgs.general.check2}
                onPress={() => onPress(index)}
                active={activeAnswers.findIndex((i: number) => i === index) !== -1}
              />
            </View>
          );
        })}
      </View>
    </>
  );
};
