import React, { FunctionComponent } from 'react';
import { makeStyles, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { COLORS } from '../../../utils/Theme';
import { IOption } from '../../../interfaces/IOption';
import { CustomText } from '../../CustomText';

const useMUIStyles = makeStyles({
  root: {
    marginRight: 6,
    color: COLORS.WHITE,
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.DATE_SELECT_PRIMARY,
      borderRadius: 16,
    },
    '&.MuiFormControl-root .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.DATE_SELECT_PRIMARY,
      borderRadius: 16,
    },
  },
});

const useMUILabelStyles = makeStyles({
  root: {
    color: COLORS.DATE_SELECT_PRIMARY,
  },
  focused: {
    '&.MuiInputLabel-outlined': {
      color: COLORS.DATE_SELECT_PRIMARY,
    },
  },
});

const useMUISelectStyles = makeStyles({
  root: {
    color: COLORS.WHITE,
  },
  icon: {
    color: COLORS.WHITE,
  },
});

interface ICustomTimeSelectProps {
  label: string;
  selected?: number;
  onChange: (value: number) => void;
  options: IOption[];
}

export const CustomTimeSelect: FunctionComponent<ICustomTimeSelectProps> = (props) => {
  const { label, selected, onChange, options } = props;

  const muiStyles = useMUIStyles();
  const muiLabelStyles = useMUILabelStyles();
  const muiSelectStyles = useMUISelectStyles();

  return (
    <FormControl fullWidth variant="outlined" classes={muiStyles}>
      <InputLabel id="demo-simple-select-label" classes={muiLabelStyles}>
        {label}
      </InputLabel>
      <Select
        value={selected}
        placeholder={label}
        label={label}
        onChange={(ev) => {
          onChange(ev.target.value as number);
        }}
        classes={muiSelectStyles}>
        <MenuItem selected={true} disabled value={-1} key={-1}>
          <CustomText medium size={13}>
            {label}
          </CustomText>
        </MenuItem>
        {options.map((option: IOption) => (
          <MenuItem key={option.value} value={option.value}>
            <CustomText bold size={13}>
              {option.label}
            </CustomText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
