import React, { FunctionComponent } from 'react';
import { View, Image, Linking } from 'react-native';
import { useLayout } from '../../context/LayoutContext';

import { IStep } from '../../interfaces/IStep';
import { Assets } from '../../utils/Assets';
import { COLORS } from '../../utils/Theme';
import { Button } from '../Button';
import { CustomText } from '../CustomText';

const useStyles = (): any => {
  const { WP } = useLayout();

  const imageWidth = 100;
  const imageHeight = imageWidth / 2.75;

  return {
    container: { alignItems: 'center' },
    header: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    splitLine: {
      width: WP(),
      height: 1,
      backgroundColor: COLORS.PAY_INACTIVE,
    },
    headerImage: {
      width: imageWidth,
      height: imageHeight,
      marginTop: 20,
      marginBottom: 10,
    },
    separatorLine: {
      width: WP(100),
      height: 1,
      backgroundColor: COLORS.SEPARATOR_LINE,
      opacity: 0.5,
    },
    title: {
      marginTop: 20,
    },
    stepContainer: {
      flexDirection: 'row',
      marginBottom: 25,
    },
    stepsContainer: {
      width: '100%',
      marginTop: 25,
    },
    numberText: {
      marginHorizontal: 25,
    },
    hintImage: {
      width: WP(80),
      height: WP(80) / 0.91019417,
      alignSelf: 'center',
      marginBottom: 25,
      marginTop: -15,
      borderRadius: 10,
    },
    downloadButton: {
      width: WP(80),
      marginBottom: 25,
    },
  };
};

interface ISpiritDownloadStepProps {
  step: IStep;
}

export const SpiritDownloadStep: FunctionComponent<ISpiritDownloadStepProps> = () => {
  const styles = useStyles();

  const onDownloadPressed = () => {
    Linking.openURL(
      'https://apps.apple.com/us/app/spirit-understand-yourself/id543397460#?platform=iphone',
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image style={styles.headerImage} source={Assets.svgs.general.spirit} />
        <View style={styles.splitLine} />
      </View>

      <CustomText size={18} semibold style={styles.title} textAlign="center">
        Simple follow these steps to access your premium account
      </CustomText>

      <View style={styles.stepsContainer}>
        <View style={styles.stepContainer}>
          <CustomText size={14} style={styles.numberText} bold color={COLORS.BLUE}>
            {'1 '}
          </CustomText>
          <CustomText size={14}>Download Spirit app </CustomText>
        </View>
        <View style={styles.stepContainer}>
          <CustomText size={14} style={styles.numberText} color={COLORS.BLUE} bold>
            2
          </CustomText>
          <CustomText size={14}>
            Go to your email client and open the app with the LINK in the email (see image below)
          </CustomText>
        </View>
        <Image source={Assets.images.general.email} style={styles.hintImage} />
        <View style={styles.stepContainer}>
          <CustomText size={14} style={styles.numberText} color={COLORS.BLUE} bold>
            3
          </CustomText>
          <CustomText size={14}>Enjoy premium access</CustomText>
        </View>
      </View>
      <Button title={'Download Spirit'} style={styles.downloadButton} onPress={onDownloadPressed} />
    </View>
  );
};
