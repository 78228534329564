import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';

import { useLayout } from '../../context/LayoutContext';
import { useOnboarding } from '../../context/OnboardingContext';
import { IStep } from '../../interfaces/IStep';
import { TRIAL_TYPES, getTrialPrice } from '../../utils/Pricing';
import { COLORS } from '../../utils/Theme';
import { format } from '../../utils/Utils';
import { Button, EBUTTONTYPE } from '../Button';
import { CustomText } from '../CustomText';
import { EmailInfo } from './EmailInfo';

const useStyles = (): any => {
  const { WP } = useLayout();

  return {
    container: {
      height: '100%',
      marginHorizontal: 10,
    },
    buttonContainer: {
      marginTop: 20,
      alignItems: 'center',
      marginBottom: 40,
      width: '100%',
    },
    button: {
      minWidth: WP(100) - 60,
    },
    title: {
      marginTop: 25,
    },
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    image: {
      maxWidth: 250,
      maxHeight: 250,
    },
    subTitle: {
      marginTop: 10,
      marginBottom: 10,
    },
    buttons: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '100%',
      marginTop: 25,
    },
    selectionButton: {
      width: 70,
      height: 70,
      borderRadius: 10,
    },
    compensate: {
      color: COLORS.BLUE,
    },
    belowButtons: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
    },
    fullFlex: {
      flex: 1,
    },
    additionalInfo: { flex: 1, justifyContent: 'center', alignItems: 'center' },
    line: { marginTop: 2, marginBottom: 2 },
    infoText: { lineHeight: 11 },
    topContainer: {
      width: WP(100),
      height: 20,
      backgroundColor: COLORS.BUTTON_SECONDARY,
      marginLeft: -30,
    },
  };
};

interface ISubscriptionPlanStepProps {
  step: IStep;
}

export const SubscriptionPlanStep: FunctionComponent<ISubscriptionPlanStepProps> = (props) => {
  const styles = useStyles();
  const { step } = props;
  const {
    gotoNext,
    setSubscription,
    setSetupIntent,
    customer,
    trialPriceType,
    setTrialPriceType,
    getFBC,
    getFBP,
  } = useOnboarding();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // eslint-disable-next-line no-undef
  const [email] = useState<string>(localStorage.getItem('email') || '');

  const trialPrice = getTrialPrice(trialPriceType!);

  const updatePrice = (type: TRIAL_TYPES) => {
    setTrialPriceType(type);
  };

  const onNext = async () => {
    try {
      gotoNext([{ type: TRIAL_TYPES.TRIAL_0, price: '20', currency: 'USD' }]);
      // setIsLoading(true);
      // const type = trialPriceType;
      // const price = getTrialPrice(trialPriceType!);
      // const currency = 'USD';

      // const evNumber = Math.random().toString(36).substr(2, 10);
      // const eventID = `${EVENTS.ADD_TO_CART}.${evNumber}`;
      // const fbp = getFBP();
      // const fbc = getFBC();
      // const result = await createSubscription(customer.id, type!, evNumber, fbp, fbc);
      // setSubscription(result.data.subscription as any);
      // setSetupIntent(result.data.setupIntent as any);

      // gotoNext([{ type, price, currency }]);

      // try {
      //   fbq('track', 'AddToCart', undefined, {
      //     eventID,
      //   });
      //   trackEvent(ANALYTICS_EVENTS.ADDTOCART);
      // } finally {
      // }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <EmailInfo email={email} />
      <CustomText style={styles.title} size={15}>
        {format(step.screen.title[0])}
      </CustomText>
      <CustomText style={styles.subTitle} bold size={15}>
        {step.screen.subtitle[0]}
      </CustomText>

      <CustomText style={styles.compensate} bold size={15}>
        {step.screen.additional[0]}
      </CustomText>

      <View style={styles.buttons}>
        <Button
          type={trialPriceType === TRIAL_TYPES.TRIAL_1 ? EBUTTONTYPE.MOON : EBUTTONTYPE.SECONDARY}
          style={styles.selectionButton}
          title={`$${getTrialPrice(TRIAL_TYPES.TRIAL_1)}`}
          onPress={() => {
            updatePrice(TRIAL_TYPES.TRIAL_1);
          }}
        />
        <Button
          type={trialPriceType === TRIAL_TYPES.TRIAL_5 ? EBUTTONTYPE.MOON : EBUTTONTYPE.SECONDARY}
          style={styles.selectionButton}
          title={`$${getTrialPrice(TRIAL_TYPES.TRIAL_5)}`}
          onPress={() => {
            updatePrice(TRIAL_TYPES.TRIAL_5);
          }}
        />
        <Button
          type={trialPriceType === TRIAL_TYPES.TRIAL_9 ? EBUTTONTYPE.MOON : EBUTTONTYPE.SECONDARY}
          style={styles.selectionButton}
          title={`$${getTrialPrice(TRIAL_TYPES.TRIAL_9)}`}
          onPress={() => {
            updatePrice(TRIAL_TYPES.TRIAL_9);
          }}
        />
        <Button
          type={trialPriceType === TRIAL_TYPES.TRIAL_13 ? EBUTTONTYPE.MOON : EBUTTONTYPE.SECONDARY}
          style={styles.selectionButton}
          title={`$${getTrialPrice(TRIAL_TYPES.TRIAL_13)}`}
          onPress={() => {
            updatePrice(TRIAL_TYPES.TRIAL_13);
          }}
        />
      </View>

      <View style={styles.belowButtons}>
        <View style={styles.fullFlex} />
        <View style={styles.fullFlex} />
        <View style={styles.fullFlex} />
        <View style={styles.additionalInfo}>
          <CustomText medium style={styles.line}>
            |
          </CustomText>
          <CustomText medium size={9} style={styles.infoText}>
            MOST
          </CustomText>
          <CustomText medium size={9} style={styles.infoText}>
            POPULAR
          </CustomText>
          <CustomText medium size={9} style={styles.infoText}>
            CHOICE*
          </CustomText>
        </View>
      </View>
      <View style={styles.buttonContainer}>
        <Button
          disabled={!trialPrice || isLoading}
          loading={isLoading}
          style={styles.button}
          title="See My Plan"
          onPress={onNext}
        />
      </View>
    </View>
  );
};
