import React, { FunctionComponent } from 'react';

import { EContentType } from '../../enums/EContentType';
import { IStep } from '../../interfaces/IStep';
import { OtherSliderStep } from './OtherSliderStep';
import { UnknownStep } from './UnknownStep';

interface IOtherStepProps {
  step: IStep;
}

export const OtherStep: FunctionComponent<IOtherStepProps> = (props) => {
  const { step } = props;

  const renderStep = () => {
    if (step.screen.contentType === EContentType.SLIDER) {
      return <OtherSliderStep step={step} />;
    } else {
      return <UnknownStep step={step} />;
    }
  };

  return renderStep();
};
