import { Line } from 'rc-progress';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { Animated, View } from 'react-native';

import { useOnboarding } from '../../context/OnboardingContext';
import { IStep } from '../../interfaces/IStep';
import { COLORS } from '../../utils/Theme';
import { format } from '../../utils/Utils';
import { CustomText } from '../CustomText';

const useStyles = (): any => {
  return {
    container: {
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      marginHorizontal: 10,
    },
    title: {
      textAlign: 'center',
    },
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    subTitle: {
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'center',
    },
    timeBarWrapper: { width: '100%', marginTop: 40, marginBottom: 30 },
    timebarLabel: {
      marginBottom: 20,
    },
    percentText: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

interface IFormTimebarStepProps {
  step: IStep;
}

export const FormTimebarStep: FunctionComponent<IFormTimebarStepProps> = (props) => {
  const styles = useStyles();
  const { step } = props;
  const { gotoNext } = useOnboarding();

  const timeLineAnim = useRef(new Animated.Value(0)).current;
  const [timeLineProgress, setTimelineProgress] = useState<number>(0);

  const animation = Animated.sequence([
    Animated.timing(timeLineAnim, {
      toValue: 23,
      duration: 2000,
      useNativeDriver: false,
    }),
    Animated.timing(timeLineAnim, {
      toValue: 43,
      duration: 1000,
      useNativeDriver: false,
    }),
    Animated.timing(timeLineAnim, {
      toValue: 77,
      duration: 3000,
      useNativeDriver: false,
    }),
    Animated.timing(timeLineAnim, {
      toValue: 87,
      duration: 2000,
      useNativeDriver: false,
    }),
    Animated.timing(timeLineAnim, {
      toValue: 97,
      duration: 1000,
      useNativeDriver: false,
    }),
    Animated.timing(timeLineAnim, {
      toValue: 100,
      duration: 1000,
      useNativeDriver: false,
    }),
  ]);

  useEffect(() => {
    if (step.screen.timebar) {
      animation.start(({ finished }) => {
        if (finished) {
          gotoNext();
        }
      });
    }
  }, [step]);

  useEffect(() => {
    timeLineAnim.addListener((event) => {
      setTimelineProgress(event.value);
    });
  }, []);

  return (
    <View style={styles.container}>
      <CustomText style={styles.title} bold size={15}>
        {format(step.screen.title)}
      </CustomText>
      <CustomText style={styles.subTitle} size={13}>
        {format(step.screen.subtitle)}
      </CustomText>
      <Lottie
        style={styles.animation}
        options={{ loop: step.screen.loop === true, animationData: step.screen.mediaUrl }}
      />

      <View style={styles.timeBarWrapper}>
        <CustomText
          style={styles.timebarLabel}
          semibold
          size={14}
          color={COLORS.MATCHUP_DONE_BACKGROUND}
          textAlign="center">
          {step.screen.timebarLabel}
        </CustomText>
        <View>
          <Line
            percent={timeLineProgress}
            strokeWidth={12}
            trailWidth={12}
            strokeLinecap="round"
            strokeColor={[COLORS.MATCHUP_DONE_BACKGROUND]}
            trailColor={COLORS.PROGRESS_BAR_BACKGROUND}
          />
          <View style={styles.percentText}>
            <CustomText size={14} textAlign="center" color={COLORS.WHITE} bold>{`${Math.floor(
              timeLineProgress,
            )} %`}</CustomText>
          </View>
        </View>
      </View>
    </View>
  );
};
