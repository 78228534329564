import axios from 'axios';
import { calculateZodiacSign, Storage, ue } from '../utils/Utils';
import { TRIAL_TYPES } from './Pricing';

const URL = process.env.BACKEND;
const PRICE = process.env.STRIPE_PRICE;

export const createCustomer = (
  email: string,
  dob: number,
  name: string,
  gender: number,
  eventID: string,
  fbp?: string,
  fbc?: string,
  searchParams?: any,
) => {
  const e = Storage.getValue(ue.BIRTH_DATE);
  const sign = Storage.getValue(ue.ZODIAC) || calculateZodiacSign(Number(e));

  return axios.post(URL + '/payment/create-customer', {
    email,
    dob: dob,
    name: name,
    gender: gender === 0 ? 'male' : 'female',
    eventID,
    fbp,
    fbc,
    sign,
    searchParams,
  });
};

export const createPaymentIntent = (customerId: string) => {
  return axios.post(URL + '/payment/create-payment-intent', { customerId });
};

export const createSubscription = (
  customerId: string,
  trialType: TRIAL_TYPES,
  eventID: string,
  fbp?: string,
  fbc?: string,
) => {
  return axios.post(URL + '/payment/create-subscription', {
    customerId,
    priceId: PRICE,
    trialType,
    fbp,
    fbc,
    eventID,
  });
};

export const updateDefaultPaymentMethod = (
  customerId: string,
  paymentMethodId: string,
  trialType: TRIAL_TYPES,
  eventPurchaseID: string,
  eventStartTrialID: string,
  fbp?: string,
  fbc?: string,
) => {
  return axios.put(URL + '/payment/update-customer-default-payment-method', {
    priceId: PRICE,
    customerId,
    paymentMethodId,
    trialType,
    eventPurchaseID,
    eventStartTrialID,
    fbp,
    fbc,
  });
};

export const updateSubscription = (
  subscriptionId: string,
  customerId: string,
  eventID: string,
  fbp?: string,
  fbc?: string,
) => {
  return axios.put(URL + '/payment/update-subscription', {
    subscriptionId,
    customerId,
    eventID,
    fbp,
    fbc,
  });
};
