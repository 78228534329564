import { Assets } from './Assets';

export const data = {
  progress: {
    colors: {
      1: '#066FDE',
      2: '#F75C03',
      3: '#04A777',
    },
    events: {
      1: 'FinalYourProfileFinished',
      2: 'FinalTitle2Finished',
      3: 'FinalTitle3Finished',
    },
    labels: {
      1: [
        {
          conditions: null,
          text: 'Your Profile',
        },
      ],
      2: [
        {
          conditions: {
            'relationship-status': 0,
          },
          text: 'Personality Traits',
        },
        {
          conditions: {
            'relationship-status': 1,
          },
          text: 'Your Partner’s Profile',
        },
      ],
      3: [
        {
          conditions: {
            'relationship-status': 0,
          },
          text: 'Relationship Pattern',
        },
        {
          conditions: {
            'relationship-status': 1,
          },
          text: 'Relationship & Personality Patterns',
        },
      ],
    },
  },
  // eslint-disable-next-line no-sparse-arrays
  map: [
    ,
    {
      map: [
        {
          nodes: [
            {
              conditions: null,
              screen: {
                type: 'welcome',
                valueKey: 'welcome',
                title: 'Understand Yourself and Improve Relationships With Astrology',
                subtitle: '1-Minute Personal Assessment',
                mediaUrl: '/assets/svg/logotype.svg',
                buttonText: 'Let’s start',
                eventKey: 'welcome',
              },
            },
          ],
        },
      ],
    },
    {
      group: 1,
      map: [
        {
          nodes: [
            {
              conditions: null,
              screen: {
                title: "What's your relationship status?",
                subtitle: 'So we can give you new insights into your love life.',
                type: 'form',
                valueKey: 'relationship-status',
                eventKey: 'relationship-status',
                contentType: 'poll',
                multiple: !1,
                values: ['Single', 'In a relationship'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 0,
              },
              screen: {
                title: 'What is your goal?',
                type: 'form',
                valueKey: 'goal',
                eventKey: 'ProfileGoal',
                contentType: 'poll',
                multiple: !1,
                allowAll: !0,
                values: ['Find my perfect partner', 'Understand myself better'],
              },
            },
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                title: 'What is your goal?',
                type: 'form',
                valueKey: 'goal',
                eventKey: 'ProfileGoal',
                contentType: 'poll',
                multiple: !1,
                allowAll: !0,
                values: ['Fix relationship problems', 'Increase relationship satisfaction'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                type: 'form',
                eventKey: 'ProfileGoalSetup',
                contentType: 'info',
                title: 'Great! You just set your first goal! ',
                subtitle: "Let's keep going so we can get to know\n you better.",
                animated: !0,
                mediaUrl: Assets.animations.shootingStar,
                buttonText: 'Next',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                title: 'What’s your gender?',
                subtitle: 'This highlights the duality of your masculine and feminine energies.',
                type: 'form',
                valueKey: 'gender',
                eventKey: 'ProfileGender',
                contentType: 'poll',
                multiple: !1,
                values: ['Male', 'Female'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                title: 'Are you a parent?',
                type: 'form',
                eventKey: 'ProfileParent',
                contentType: 'poll',
                multiple: !1,
                values: ['Yes', 'No'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                title: 'What’s your date of birth?',
                type: 'form',
                valueKey: 'date-of-birth',
                eventKey: 'ProfileDateOfBirth',
                contentType: 'date',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                title: 'Do you know your time of birth?',
                type: 'form',
                eventKey: 'ProfileTimeOfBirthQestion',
                contentType: 'poll',
                multiple: !1,
                values: ['Yes', 'No'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                ProfileTimeOfBirthQestion: 0,
              },
              screen: {
                title: 'At what time were you born?',
                subtitle:
                  'We use NASA data to identify the exact position of the planets in the sky at the time of your birth.',
                type: 'form',
                valueKey: 'time-of-birth',
                eventKey: 'ProfileTimeOfBirth',
                contentType: 'time',
              },
            },
            {
              conditions: {
                ProfileTimeOfBirthQestion: 1,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  'No problem! You can still find plenty of great insights without knowing your\n exact birth time.',
                subtitle:
                  'Tip: If you find out later, you can adjust\n this in your profile settings.',
                animated: !0,
                mediaUrl: Assets.animations.clock,
                valueKey: 'time-of-birth',
                eventKey: 'ProfileTimeOfBirth',
                buttonText: 'Next',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                title: 'Where were you born?',
                subtitle: 'This determines the time zone at the\n place of your birth.',
                type: 'form',
                valueKey: 'place-of-birth',
                eventKey: 'ProfilePlaceOfBirth',
                contentType: 'place',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                type: 'form',
                contentType: 'timebar',
                title:
                  "We've helped [GET=ZODIAC_NUMBER] other [GET=ZODIAC] [GET=GENDER] to [GET=VP] and we can't wait to help you too!",
                subtitle: '*as of Oktober 2021',
                additional: 'Connecting to database...',
                animated: !0,
                loop: true,
                delay: [12e3, 14e3],
                timebar: !0,
                timebarLabel: 'Connecting to database...',
                mediaUrl: Assets.animations.natalChart,
                eventKey: 'ProfileConnectingDatabase',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                title: 'Which of the following astrology apps have you used in the past 12 months?',
                type: 'form',
                eventKey: 'ProfileAstrologyApps',
                contentType: 'poll',
                multiple: !0,
                values: ['Spirit', 'Co-Star', 'The Pattern', 'Zodianic', 'Nebula'],
                buttonText: 'Next',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                title:
                  'Spirit uses NASA data to produce your\n astrological blueprint. When it comes\n to astrology, you are:',
                type: 'form',
                eventKey: 'ProfileAstrologyKnowlage',
                contentType: 'poll',
                multiple: !1,
                values: ['An expert', 'Curious', 'A beginner'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                ProfileAstrologyKnowlage: 0,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  'Great! You should know what we’re all about. Let’s see if we can show you something new.',
                animated: !0,
                loop: true,
                mediaUrl: Assets.animations.star,
                valueKey: 'profile-step',
                delay: 7e3,
              },
            },
            {
              conditions: {
                ProfileAstrologyKnowlage: 1,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  "Let's reward your curiosity! The next few questions will let us know more about you.",
                animated: !0,
                loop: true,
                mediaUrl: Assets.animations.star,
                valueKey: 'profile-step',
                delay: 6e3,
              },
            },
            {
              conditions: {
                ProfileAstrologyKnowlage: 2,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  'Perfect. We’ve built our guidance with beginners in mind. Let us help you improve your knowledge.',
                animated: !0,
                loop: true,
                mediaUrl: Assets.animations.star,
                valueKey: 'profile-step',
                delay: 6e3,
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                title:
                  '[GET=GENDER] [GET=AGE_GROUP] [GET=KIDS] need a slightly different approach to improve their relationship.\nWhich statement best describes you?',
                type: 'form',
                eventKey: 'ProfileProblemRelationship',
                contentType: 'poll',
                multiple: !1,
                values: [
                  "I'm very unhappy with how things are going in my relationship",
                  "I'm unhappy with parts of my relationship, but some things are working well",
                  "I'm generally happy in my relationship",
                ],
              },
            },
            {
              conditions: {
                'relationship-status': 0,
              },
              screen: {
                title:
                  'Single [GET=GENDER] [GET=AGE_GROUP] [GET=KIDS] need a slightly different approach to [GET=VP]. But first, how did you feel in your last relationship?',
                type: 'form',
                eventKey: 'ProfileProblemSingle',
                contentType: 'poll',
                multiple: !1,
                values: [
                  'I was unhappy with how things were going in my relationship',
                  'I was unhappy with parts of my relationship, but some things were working',
                  'I was generally happy with my relationship',
                  "I've never been in a relationship",
                ],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 0, // Single
                ProfileProblemSingle: 2, // I was generally happy with my relationship
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  "Sometimes that happens and you need to move on! We'll give you insights on how to build a long lasting relationship with your next partner.",
                subtitle: 'Now, we need some information about your [GROUP=2] to dig deeper!',
                animated: !0,
                mediaUrl: Assets.animations.key,
                eventKey: 'TraitsStarted',
                buttonText: 'Next',
              },
            },
            {
              conditions: {
                'relationship-status': 0,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  "To find out what works for us, we often need to understand what doesn't. We’ll give you insights on how to build a\n happy and lasting relationship with your new partner.",
                subtitle: 'Now, we need some information about your [GROUP=2] to dig deeper!',
                animated: !0,
                mediaUrl: Assets.animations.key,
                eventKey: 'TraitsStarted',
                buttonText: 'Next',
              },
            },
            {
              conditions: {
                'relationship-status': 1,
                ProfileProblemRelationship: 0,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  'We’ve got you covered!\nWe’ll start with small, personalized insights into you and your partner’s personality traits.',
                subtitle:
                  'Now, we need some information about [GROUP=2] to create the astrological synastry blueprint between you and your partner.',
                animated: !0,
                mediaUrl: Assets.animations.key,
                eventKey: 'ProfileBlueprint',
                buttonText: 'Next',
              },
            },
            {
              conditions: {
                'relationship-status': 1,
                ProfileProblemRelationship: 1,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  'You’re probably doing better than you think! We’ll help you identify how to improve and stick with it.',
                subtitle:
                  'Now, we need some information about [GROUP=2] to create the astrological synastry blueprint between you and your partner.',
                animated: !0,
                mediaUrl: Assets.animations.key,
                eventKey: 'ProfileBlueprint',
                buttonText: 'Next',
              },
            },
            {
              conditions: {
                'relationship-status': 1,
                ProfileProblemRelationship: 2,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  "Wonderful!\nLet's find out what's working (and what isn’t) and go from there.",
                subtitle:
                  'Now, we need some information about [GROUP=2] to create the astrological synastry blueprint between you and your partner.',
                animated: !0,
                mediaUrl: Assets.animations.key,
                eventKey: 'ProfileBlueprint',
                buttonText: 'Next',
              },
            },
          ],
        },
      ],
    },
    {
      group: 2,
      map: [
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                title: 'What is your partner’s date of birth?',
                subtitle:
                  'Their date of birth is essential information to build your partner’s personal astrological blueprint.',
                type: 'form',
                eventKey: 'PartnerDateOfBirth',
                contentType: 'date',
                buttonText: 'Next',
              },
            },
            {
              conditions: {
                'relationship-status': 0,
              },
              screen: {
                title:
                  'Do you relate to the statement below?\n\n“I really dislike being alone as much as I hate to admit it.”',
                type: 'form',
                eventKey: 'TraitsStatementOne',
                contentType: 'poll',
                values: ['Yes', 'No'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                title: 'Do you know what time your partner was born?',
                type: 'form',
                eventKey: 'PartnerTimeOfBirthQestion',
                contentType: 'poll',
                values: ['Yes', 'No'],
              },
            },
            {
              conditions: {
                'relationship-status': 0,
              },
              screen: {
                title: 'Is emotional control tricky for you?',
                type: 'form',
                eventKey: 'TraitsEmotional',
                contentType: 'poll',
                values: ['Yes', 'Sometimes', 'Rarely', 'Not at all'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 1,
                PartnerTimeOfBirthQestion: 0,
              },
              screen: {
                title: 'At what time was your partner born?',
                subtitle:
                  "We use NASA data to identify the exact position of the planets in the sky at the time of your partner's birth.",
                type: 'form',
                eventKey: 'PartnerTimeOfBirth',
                contentType: 'time',
              },
            },
            {
              conditions: {
                'relationship-status': 1,
                PartnerTimeOfBirthQestion: 1,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  'No problem! You can still find plenty of great insights without knowing the exact birth time of your partner.',
                subtitle:
                  'Tip: If you find out later, you can adjust this in your partner’s profile.',
                animated: !0,
                mediaUrl: Assets.animations.clock,
                eventKey: 'PartnerTimeOfBirth',
                buttonText: 'Next',
              },
            },
            {
              conditions: {
                'relationship-status': 0,
                TraitsEmotional: 0,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  'You’re not alone.\n A lot of [GET=ZODIAC] people find it difficult to control emotions due to their zodiac sign traits. Don’t worry, we know how to address that!',
                animated: !0,
                mediaUrl: Assets.animations.umbrella,
                eventKey: 'TraitsData',
                buttonText: 'Next',
              },
            },
            {
              conditions: {
                'relationship-status': 0,
                TraitsEmotional: 1,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  'You’re not alone.\n A lot of [GET=ZODIAC] people find it difficult to control emotions due to their zodiac sign traits. Don’t worry, we know how to address that!',
                animated: !0,
                mediaUrl: Assets.animations.umbrella,
                eventKey: 'TraitsData',
                buttonText: 'Next',
              },
            },
            {
              conditions: {
                'relationship-status': 0,
                TraitsEmotional: 2,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  'All right!\n You keep your emotions in check [GET=ZODIAC], but sometimes people might want you to show more passion. We can give you some insights about that!',
                animated: !0,
                mediaUrl: Assets.animations.sun,
                eventKey: 'TraitsData',
                buttonText: 'Next',
              },
            },
            {
              conditions: {
                'relationship-status': 0,
                TraitsEmotional: 3,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  'All right!\nYou keep your emotions in check [GET=ZODIAC], but sometimes people might want you to show more passion. We can give you some insights about that!',
                animated: !0,
                mediaUrl: Assets.animations.sun,
                eventKey: 'TraitsData',
                buttonText: 'Next',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                title: 'Where was your partner born?',
                subtitle:
                  'If you don’t know the exact place of birth just type in the country of birth.',
                type: 'form',
                eventKey: 'PartnerPlaceOfBirth',
                contentType: 'place',
              },
            },
            {
              conditions: {
                'relationship-status': 0,
              },
              screen: {
                title: 'Do you tend to notice what’s wrong\n more than what’s right?',
                type: 'form',
                eventKey: 'TraitsQestionOne',
                contentType: 'poll',
                values: ['Very frequently', 'Frequently', 'Occasionally', 'Rarely', 'Never'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 0,
              },
              screen: {
                title: 'Are you sensitive to criticism?',
                type: 'form',
                eventKey: 'TraitsQestionTwo',
                contentType: 'poll',
                values: ['Very frequently', 'Frequently', 'Occasionally', 'Rarely', 'Never'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                type: 'form',
                contentType: 'info',
                title: "Almost there!\n Now let's tailor your plan by understanding the [GROUP=3].",
                subtitle:
                  'Please take your time when answering.\n Each response is used to craft your guidance plan.',
                animated: !0,
                mediaUrl: Assets.animations.magnifyingGlass,
                eventKey: 'PatternsStarted',
                buttonText: 'Next',
              },
            },
          ],
        },
      ],
    },
    {
      group: 3,
      map: [
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 0,
              },
              screen: {
                title:
                  'Let\'s answer a few more questions\n about you. Do you agree with the statement below?\n"When I am in a relationship, sex is a priority."',
                type: 'form',
                eventKey: 'PatternsStatementOne',
                contentType: 'poll',
                values: ['Strongly agree', 'Agree', 'Neutral', 'Disagree', 'Strongly disagree'],
              },
            },
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                title:
                  'Do you agree with the statement\n below?\n"My partner and I can talk about any issue together"',
                type: 'form',
                eventKey: 'PatternsStatementOne',
                contentType: 'poll',
                values: ['Strongly agree', 'Agree', 'Neutral', 'Disagree', 'Strongly disagree'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                type: 'form',
                contentType: 'poll',
                title: 'Are any of these factors currently\n affecting your relationship?',
                eventKey: 'PatternsFactors',
                values: [
                  'Infidelity',
                  'Depression',
                  'Low sexual desire',
                  'Poor body image',
                  'Postpartum depression',
                  'Anxiety',
                  'Parenting issues',
                  'Blended family Issues',
                  'Divorce is on the table',
                  'None of these',
                ],
                multiple: !0,
                buttonText: 'Next',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                type: 'form',
                contentType: 'poll',
                title:
                  'Do you agree with the statement below?\n“My partner and I make sex a priority in our relationship”',
                eventKey: 'PatternsStatementTwo',
                values: ['Strongly agree', 'Agree', 'Neutral', 'Disagree', 'Strongly disagree'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                type: 'form',
                contentType: 'poll',
                title: 'Are you satisfied with how you and your partner communicate?',
                eventKey: 'PatternsQestionOne',
                values: ['Yes', 'No'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 1,
                PatternsQestionOne: 1,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  'Well done!\nBased on our data only the top [GET=zodiacs_match]% of [GET=Zodiac] find it easy to communicate with their [GET=Partner_Zodiac] partner.',
                animated: !0,
                mediaUrl: Assets.animations.sun,
                eventKey: 'PatternsData',
                buttonText: 'Next',
              },
            },
            {
              conditions: {
                'relationship-status': 1,
                PatternsQestionOne: 0,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                title:
                  'You’re not alone.\nBased on our data [GET=zodiacs_match]% of [GET=Zodiac] find it difficult to communicate with their [GET=Partner_Zodiac] partner. We can help you improve this.',
                animated: !0,
                mediaUrl: Assets.animations.umbrella,
                eventKey: 'PatternsData',
                buttonText: 'Next',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                type: 'form',
                contentType: 'poll',
                title:
                  'Do you agree with the statement\n below?\n“Strengthening our emotional connection is a priority to both my partner and me”',
                eventKey: 'PatternsStatementThree',
                values: ['Strongly agree', 'Agree', 'Neutral', 'Disagree', 'Strongly disagree'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                type: 'form',
                eventKey: 'PatternsEmotionalConnection',
                contentType: 'spirit',
                title: 'Spirit helps *to strengthen emotional connection*',
                subtitle:
                  '"It\'s been a great relief finding the right words to say and changing my perspective on my relationship with myself and my partner."\r-Sarah-',
                additional:
                  '*78% of users who use Spirit and adopt astrological guidance can expect to see positive changes every week.',
                values: [
                  {
                    conditions: {
                      'age-group': 20,
                    },
                    mediaUrl: Assets.images.onboarding.couple.age_20,
                  },
                  {
                    conditions: {
                      'age-group': 30,
                    },
                    mediaUrl: Assets.images.onboarding.couple.age_30,
                  },
                  {
                    conditions: {
                      'age-group': 40,
                    },
                    mediaUrl: Assets.images.onboarding.couple.age_40,
                  },
                  {
                    conditions: null,
                    mediaUrl: Assets.images.onboarding.couple.age_50,
                  },
                ],
                buttonText: 'Next',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                title: 'Are you satisfied with the way you and your partner deal with conflict?',
                type: 'form',
                eventKey: 'PatternsQestionTwo',
                contentType: 'poll',
                values: [
                  'Yes, I prefer to be honest and direct',
                  'Depends on the situation and the person',
                  "No, I don't want to get hurt or hurt another person",
                  'No, it makes me nervous',
                ],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                title: 'When you think about your relationship goals, you feel...?',
                type: 'form',
                eventKey: 'PatternsQestionThree',
                contentType: 'poll',
                values: [
                  'Optimistic! They are totally doable, with some guidance.',
                  "Cautious. I've struggled before, but I'm hopeful.",
                  "I'm feeling a little anxious, honestly.",
                ],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                PatternsQestionThree: 0,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                eventKey: 'PatternsZodiac',
                title: 'You’ve come to the right place, [GET=ZODIAC]!',
                subtitle:
                  'Knowing that your goal is already doable puts you ahead. Let’s keep going so we can build a guidance plan based on your astrological blueprint.',
                animated: !0,
                mediaUrl: Assets.animations.archery,
              },
            },
            {
              conditions: {
                PatternsQestionThree: 1,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                eventKey: 'PatternsZodiac',
                title: '[GET=ZODIAC], here’s the thing...\nYou can do this!',
                subtitle:
                  "We'll start with small insights so it doesn't feel too overwhelming. Let's keep going and build a guidance plan based on your astrological blueprint.",
                animated: !0,
                mediaUrl: Assets.animations.compass,
              },
            },
            {
              conditions: {
                PatternsQestionThree: 2,
              },
              screen: {
                type: 'form',
                contentType: 'info',
                eventKey: 'PatternsZodiac',
                title: 'It’s totally normal to feel nervous,\n [GET=ZODIAC].',
                subtitle:
                  "But we know you can do this. Let's keep going and build a guidance plan based on your astrological blueprint.",
                animated: !0,
                mediaUrl: Assets.animations.clouds,
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                'relationship-status': 0,
              },
              screen: {
                title: 'Do you find it easy to communicate with other people?',
                type: 'form',
                eventKey: 'PatternsQestionOne',
                contentType: 'poll',
                values: ['Yes', 'No'],
              },
            },
            {
              conditions: {
                'relationship-status': 1,
              },
              screen: {
                title:
                  'Do you agree with the statement below?\n“My partner makes me feel really appreciated.”',
                type: 'form',
                eventKey: 'PatternsStatementFour',
                contentType: 'poll',
                values: ['Strongly agree', 'Agree', 'Neutral', 'Disagree', 'Strongly disagree'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                title: 'Do you make decisions with your head\n or your heart?',
                type: 'form',
                eventKey: 'PatternsDecision',
                contentType: 'poll',
                allowAll: !0,
                values: ['Heart', 'Head'],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: {
                PatternsDecision: 0,
              },
              screen: {
                type: 'form',
                eventKey: 'PatternsFinal',
                contentType: 'info',
                title:
                  "Based on our data, [GET=head_heart_both]% of [GET=Zodiac] people also make decisions with their Heart. But don't worry, we'll consider that while creating your guidance plan.",
                animated: !0,
                mediaUrl: Assets.animations.heart,
                loop: false,
              },
            },
            {
              conditions: {
                PatternsDecision: 1,
              },
              screen: {
                type: 'form',
                eventKey: 'PatternsFinal',
                contentType: 'info',
                title:
                  "Based on our data, [GET=head_heart_both]% of [GET=Zodiac] people also make decisions with their Head. But don't worry, we'll consider that while creating your guidance plan.",
                animated: !0,
                mediaUrl: Assets.animations.head,
                loop: false,
              },
            },
            {
              conditions: {
                PatternsDecision: 2,
              },
              screen: {
                type: 'form',
                eventKey: 'PatternsFinal',
                contentType: 'info',
                title:
                  'Wonderful!\n Based on our data, only the top [GET=head_heart_both]% of [GET=Zodiac] people make decisions with their heart and head. Using both in equal measure is the key to feeling harmonious in your relationships.',
                animated: !0,
                mediaUrl: Assets.animations.heartHead,
                loop: false,
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                title: 'Where did you hear about us?',
                type: 'form',
                eventKey: 'FinalSurvey',
                contentType: 'poll',
                progress: false,
                values: [
                  'Poster or Billboard',
                  'Friend or Family',
                  'Instagram',
                  'Direct Mail or Package Insert',
                  'Online TV or Streaming TV',
                  'TV',
                  'Radio',
                  'Search Engine (Google, Bing. etc.)',
                  'Newspaper or Magazine',
                  'Facebook',
                  'Blog Post or Website Review',
                  'Podcast',
                  'Influencer',
                  'Youtube',
                  'Pinterest',
                  'Other',
                ],
              },
            },
          ],
        },
      ],
    },
    {
      map: [
        {
          nodes: [
            {
              conditions: null,
              screen: {
                type: 'building-guidance',
                name: 'building-guidance',
                subtitle:
                  'Sit tight! We’re building your perfect guidance\n plan based on your unique astrological\n blueprint and millions of data points from\n successful Spirit users.',
                values: [
                  {
                    group: 1,
                    progress: {
                      0: 'Analyzing your profile...',
                      20: 'Identifying the position of the planets at the moment of your birth...',
                      64: 'Creating your astrological blueprint...',
                    },
                    accel: [
                      {
                        range: [0, 42],
                        mode: 'accel',
                      },
                      {
                        range: [42, 46],
                        mode: 'ease',
                      },
                      {
                        range: [46, 82],
                        mode: 'normal',
                      },
                      {
                        range: [82, 86],
                        mode: 'ease',
                      },
                      {
                        range: [86, 100],
                        mode: 'accel',
                      },
                    ],
                  },
                  {
                    group: 2,
                    progress: {
                      0: 'Assessing personality profile...',
                      27: [
                        {
                          conditions: {
                            'relationship-status': 'In a relationship',
                          },
                          text: 'Creating synastry chart...',
                        },
                        {
                          conditions: {
                            'relationship-status': 'Single',
                          },
                          text: 'Identifying your strengths and weaknesses...',
                        },
                      ],
                      67: 'Analyzing your compatibility...',
                    },
                    accel: [
                      {
                        range: [0, 38],
                        mode: 'accel',
                      },
                      {
                        range: [38, 40],
                        mode: 'ease',
                      },
                      {
                        range: [40, 80],
                        mode: 'normal',
                      },
                      {
                        range: [80, 100],
                        mode: 'accel',
                      },
                    ],
                  },
                  {
                    group: 3,
                    progress: {
                      0: 'Analyzing relationship needs...',
                      25: 'Charting best guidance plan...',
                      61: 'Predicting future results...',
                    },
                    accel: [
                      {
                        range: [0, 24],
                        mode: 'normal',
                      },
                      {
                        range: [24, 25],
                        mode: 'ease',
                      },
                      {
                        range: [25, 100],
                        mode: 'accel',
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                type: 'form',
                valueKey: 'email',
                eventKey: 'FinalEmail',
                contentType: 'email',
                agreement: !0,
                placeholder: 'Your email',
                title: 'Enter your email to see how you can [GET=VP3] with Spirit',
                additional:
                  "*Spirit does not share any personal information. We'll email you a copy of your program for convenient access.",
                buttonText: 'Continue',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                type: 'other',
                name: 'get-started',
                eventKey: 'FinalAnimation',
                contentType: 'slider',
                values: [
                  {
                    align: 'center',
                    eventKey: 'FinalAnimation1',
                    elements: [
                      {
                        type: 'text',
                        value: 'Welcome to Spirit',
                      },
                    ],
                    delay: 3e3,
                  },
                  {
                    eventKey: 'FinalAnimation2',
                    elements: [
                      {
                        type: 'text',
                        value: 'Based on your answers,',
                      },
                    ],
                    delay: 3e3,
                  },
                  {
                    elements: [
                      {
                        type: 'text',
                        value: 'we’ve created your astrological blueprint and guidance plan',
                      },
                    ],
                    eventKey: 'FinalAnimation3',
                    delay: 3e3,
                  },
                  {
                    elements: [
                      {
                        type: 'text',
                        value: 'to help you better understand yourself[GET=AND_PARTNER]',
                      },
                    ],
                    eventKey: 'FinalAnimation4',
                    delay: 3e3,
                  },
                  {
                    elements: [
                      {
                        type: 'text',
                        value: 'and to improve your relationship - for good.',
                      },
                    ],
                    eventKey: 'FinalAnimation5',
                    delay: 3e3,
                  },
                  {
                    elements: [
                      {
                        type: 'text',
                        value: 'Let’s get started.',
                      },
                    ],
                    eventKey: 'FinalAnimation6',
                    delay: 3e3,
                  },
                ],
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                type: 'subscription-plan',
                eventKey: 'subscription-plan',
                contentType: 'subscription_plan',
                values: [
                  {
                    planCode: '2_month_1',
                    addon: 'setup_fee_1',
                    price: '1',
                    currency: 'USD',
                  },
                  {
                    planCode: '2_month_5',
                    addon: 'setup_fee_5',
                    price: '5',
                    currency: 'USD',
                  },
                  {
                    planCode: '2_month_9',
                    addon: 'setup_fee_9',
                    price: '9',
                    currency: 'USD',
                  },
                  {
                    planCode: '2_month_13',
                    addon: 'setup_fee_13',
                    price: '13.21',
                    currency: 'USD',
                  },
                  {
                    planCode: '1_month_1',
                    addon: 'setup_fee_1',
                    price: '1',
                    currency: 'USD',
                  },
                  {
                    planCode: '1_month_5',
                    addon: 'setup_fee_5',
                    price: '5',
                    currency: 'USD',
                  },
                  {
                    planCode: '1_month_9',
                    addon: 'setup_fee_9',
                    price: '9',
                    currency: 'USD',
                  },
                  {
                    planCode: '1_month_13',
                    addon: 'setup_fee_13',
                    price: '13.21',
                    currency: 'USD',
                  },
                ],
                title: [
                  "We've helped millions of people to have happier lives and better relationships, and we want to help you too.",
                  '',
                ],
                subtitle: [
                  'Money shouldn’t stand in the way of finding astrology guidance that finally works. So, choose an amount that you think is reasonable to try us out for one week.',
                  'We love helping people to improve their life and relationship through astrology. That why we’re letting you choose the cost of your 1-week trial.',
                  'We want everyone to discover the benefits of astrology. That why we’re letting you choose the cost of your 1-week trial.',
                ],
                additional: [
                  'It costs us $13.21 to compensate our Spirit employees for the trial, but please choose the amount you are comfortable with.',
                  'It costs us $13.21 to compensate our Spirit employees for the trial, but how much do you want to pay? ',
                  'It costs us $13.21 to compensate our Spirit employees for the trial, but please choose the amount you are comfortable with.',
                ],
                buttonText: 'See My Plan',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                type: 'subscription-info',
                valueKey: 'subscription-info',
                eventKey: 'subscription-info',
                title: 'Hey [GET=ZODIAC],',
                subtitle: [
                  'Based on your answers and astrological blueprint, we prepared your guidance plan.',
                ],
                additional: 'Your plan has been reserved for the next 15 minutes!',
                buttonText: 'Continue',
              },
            },
          ],
        },
        // {
        //   nodes: [
        //     {
        //       conditions: null,
        //       screen: {
        //         title: "Don't plan on looking back? ",
        //         subtitle: [
        //           'Get a head start and take *23% off your\n first 2-month plan* by skipping the trial and\n starting today!',
        //           'Get a head start and take *17% off your\n first 2-month plan* by skipping the trial and\n starting today!',
        //           'Get a head start and take *11% off your\n first 2-month plan* by skipping the trial and\n starting today!',
        //           'Get a head start and take *23% off your\n first 1-month plan* by skipping the trial and\n starting today!',
        //           'Get a head start and take *17% off your\n first 1-month plan* by skipping the trial and\n starting today!',
        //           'Get a head start and take *11% off your\n first 1-month plan* by skipping the trial and\n starting today!',
        //         ],
        //         type: 'form',
        //         valueKey: 'offer',
        //         eventKey: 'ProfileRelationshipStatus',
        //         contentType: 'info',
        //         multiple: !1,
        //         priceBox: !0,
        //         buttonText: 'Download Spirit',
        //       },
        //     },
        //   ],
        // },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                type: 'payment-updator',
                valueKey: 'payment-updator',
                eventKey: 'payment-updator',
                title: 'Update your payment method',
                buttonText: 'Update',
                additional: 'Skip Trial',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                type: 'skip-trial',
                eventKey: 'skip-trial',
              },
            },
          ],
        },
        {
          nodes: [
            {
              conditions: null,
              screen: {
                type: 'download',
                title: 'Simply follow these steps to\n access your account  ',
                eventKey: 'download',
                subtitle:
                  'To enter your program and access your account, you have to sign in with your email in the app.',
                buttonText: 'Download Spirit',
              },
            },
          ],
        },
      ],
    },
  ].reduce(
    (e, t) =>
      e.concat(
        (function ({ group: e, map: t = [] }) {
          const result = t.map((t: any, idx: any) => {
            return Object.assign(Object.assign({}, t), {
              group: e,
              idx,
            });
          });

          return result.map((r: any) => ({ ...r, total: result.length }));
        })(t),
      ),
    [],
  ),
};
