import { Assets } from './Assets';
export const REVIEWS = {
  '1': {
    '1': [
      {
        name: 'Liz Hoffman',
        avatar: Assets.images.onboarding.reviewAvatar.liz,
        daysAgo: 1,
        review:
          'As a [GET=Zodiac_Normal] Sun I can be to emotional sumtimes. But nowI’ve learned loads about how to say wat I want without getting upset. ',
      },
      {
        name: 'Marie-Michelle Ricci',
        avatar: Assets.images.onboarding.reviewAvatar.marie,
        daysAgo: 2,
        review:
          'My partner and I used to fight all the time. My astrologer told me why.. Now things are alot better!!',
      },
      {
        name: 'Shauna Taylor',
        avatar: Assets.images.onboarding.reviewAvatar.shauna,
        daysAgo: 4,
        review:
          'I was pretty shocked at how accurrate all the stuff about my husband[GET=Has_Kids].  ',
      },
      {
        name: 'Latisha George',
        avatar: Assets.images.onboarding.reviewAvatar.latisha,
        daysAgo: 4,
        review: 'Honestly: I reckon astrology saved my marriage. I can’t thank u enough for that!!',
      },
      {
        name: 'Kristen Da Silva',
        avatar: Assets.images.onboarding.reviewAvatar.kristen,
        daysAgo: 12,
        review:
          'My astrologer has made me look at my relationship in a a whole new light. Thank you!!',
      },
    ],
    '0': [
      {
        name: 'Jessica Stewart',
        avatar: Assets.images.onboarding.reviewAvatar.jessica,
        daysAgo: 1,
        review:
          'I wasn’t sure if this app would help me at first, but I learnt so much. The stuff about my relationship and career was spot on. ',
      },
      {
        name: 'Marina Papageorgiou',
        avatar: Assets.images.onboarding.reviewAvatar.marina,
        daysAgo: 3,
        review: 'I think my astrologer knows me better than I know myself these days! Hehe!',
      },
      {
        name: 'Yolanda Parks',
        avatar: Assets.images.onboarding.reviewAvatar.yolanda,
        daysAgo: 3,
        review:
          'I never thought the stars and planets would reveal so much. Things make alot more sense for me now. ',
      },
      {
        name: 'Britney Van Beek ',
        avatar: Assets.images.onboarding.reviewAvatar.britney,
        daysAgo: 6,
        review:
          'I was feeling pretty lost and astrology has helped me know who I am and what i really want from life and relationship. ',
      },
      {
        name: 'Johanna Tiamo',
        avatar: Assets.images.onboarding.reviewAvatar.johanna,
        daysAgo: 9,
        review:
          'All the stuff I learnt about my career and my love life. Man! It was spookily accurate!  ',
      },
    ],
  },
  '0': {
    '1': [
      {
        name: 'Raj Betts',
        avatar: Assets.images.onboarding.reviewAvatar.bob,
        daysAgo: 1,
        review:
          'I was pretty shocked at how accurrate all the stuff about my [GET=Partner_Zodiac_Normal] partner [GET=Has_Kids]. ',
      },
      {
        name: 'Ned Hoffman ',
        avatar: Assets.images.onboarding.reviewAvatar.ned,
        daysAgo: 1,
        review:
          'I’ve found out sum pretty cool things. And yeah. I reckon it’s helped me know what my partner wants.',
      },
      {
        name: 'Kevin Koslowski ',
        avatar: Assets.images.onboarding.reviewAvatar.kevin,
        daysAgo: 3,
        review:
          'Talking to an astrologer has def helped.. Man! Some of the stuff she knew was crazy!!!',
      },
      {
        name: 'Lamarr George ',
        avatar: Assets.images.onboarding.reviewAvatar.lamarr,
        daysAgo: 7,
        review:
          'I always thought astrology was a load of jibber jabber nonsense, but now I’ve changed my mind.',
      },
      {
        name: 'Sean Taylor ',
        avatar: Assets.images.onboarding.reviewAvatar.sean,
        daysAgo: 8,
        review: 'My astrologer has made me look at things in a a whole new light. Thank you!!',
      },
    ],
    '0': [
      {
        name: 'Dick Champion',
        avatar: Assets.images.onboarding.reviewAvatar.dick,
        daysAgo: 1,
        review:
          'I’ve found out sum pretty cool things. And yeah. I reckon it’s helped me to improve my relationship',
      },
      {
        name: 'Justin Snyder',
        avatar: Assets.images.onboarding.reviewAvatar.justin,
        daysAgo: 4,
        review:
          'I always knew I was an [GET=Zodiac_Normal] but didnt know all that other stuff about me. Now I go round tryin to figure out everyone’s signs!',
      },
      {
        name: 'Aaron Wilson',
        avatar: Assets.images.onboarding.reviewAvatar.aaron,
        daysAgo: 5,
        review:
          'All that stuff I learned about my career and my love life. Man! It was spookily accurate!  ',
      },
      {
        name: 'Evan Wyatt',
        avatar: Assets.images.onboarding.reviewAvatar.evan,
        daysAgo: 6,
        review:
          'I wasn’t sure what to ask an astrologer at first, but I learnt so much. The stuff about my relationship and career was spot on. ',
      },
      {
        name: 'Jack Goff',
        avatar: Assets.images.onboarding.reviewAvatar.jack,
        daysAgo: 10,
        review: 'My astrologer has made me look at things in a a whole new light. Thank you!!',
      },
    ],
  },
};
