import { Modal } from '@material-ui/core';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { TextInput, TouchableOpacity, View, Image } from 'react-native';
import LockIcon from '@material-ui/icons/Lock';
import { Toaster } from 'react-hot-toast';

import { useLayout } from '../../../context/LayoutContext';
import { Assets } from '../../../utils/Assets';
import { COLORS } from '../../../utils/Theme';
import { Button, EBUTTONTYPE } from '../../Button';
import { CustomText } from '../../CustomText';
import { PayDisclaimer } from './PayDisclaimer';

const useStyles = (): any => {
  const { WP, HP } = useLayout();
  const closeSize = 30;
  const brandWidth = WP(60);
  const brandHeight = brandWidth / 8.14;

  return {
    icon: {
      marginRight: 8,
      width: 16,
      height: 16,
    },
    nameInput: {
      color: 'white',
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '700',
      fontSize: '15px',
      backgroundColor: 'transparent',
      outline: 'none !important',
      outlineWidth: 0,
      padding: 0,
      margin: 0,
    },
    stripeElementStyle: {
      color: 'white',
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '700',
      fontSize: '15px',
      '::placeholder': {
        color: COLORS.PAY_INACTIVE,
      },
      backgroundColor: 'transparent',
      height: '1.2em',
      iconColor: COLORS.WHITE,
    },
    modalView: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    expCvc: { flexDirection: 'row' },
    container1: {
      justifyContent: 'center',
      alignItems: 'center',
      width: WP(),
      height: HP(),
    },
    modalContainer: {
      width: WP(95),
      minHeight: HP(60),
      backgroundColor: COLORS.BACKGROUND_SOLID,
      borderRadius: 20,
      padding: 20,
    },
    modalHeader: {
      alignItems: 'center',
    },
    closeButton: { alignSelf: 'flex-end' },
    close: {
      width: closeSize,
      height: closeSize,
    },
    closeWrapper: {
      width: closeSize,
      height: closeSize,
      borderRadius: closeSize,
      backgroundColor: COLORS.CHAT_BUBBLE_BACKGROUND,
    },
    nameWrapper: {
      borderWidth: 1,
      borderColor: COLORS.WHITE,
      borderStyle: 'solid',
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 11,
      paddingBottom: 11,
      borderRadius: 15,
      marginBottom: 15,
      flex: 1,
    },
    elementWrapper: {
      borderWidth: 1,
      borderColor: COLORS.WHITE,
      borderStyle: 'solid',
      padding: 15,
      borderRadius: 15,
      marginBottom: 15,
      flex: 1,
    },
    brands: {
      width: brandWidth,
      height: brandHeight,
      marginTop: 15,
    },
    splitLine3: {
      width: '100%',
      height: 1,
      backgroundColor: COLORS.PAY_INACTIVE,
      marginVertical: 15,
    },
    leftElement: {
      marginRight: 5,
    },
    rightElement: {
      marginLeft: 5,
    },
    stripeElementInvalidStyle: {
      color: COLORS.ERROR,
      iconColor: COLORS.ERROR,
    },
    button: {
      marginTop: 15,
    },
    toast: {
      fontFamily: "'Poppins', sans-serif",
      color: COLORS.WHITE,
      backgroundColor: COLORS.BACKGROUND_SOLID,
    },
  };
};

interface ICCModalProps {
  modalOpen: boolean;
  onCloseCreditCard: () => void;
  handleSubmit: any;
  name: string;
  onNameChange: (name: string) => void;
  loading: boolean;
}

export const CCModal: FunctionComponent<ICCModalProps> = (props) => {
  const styles = useStyles();
  const { modalOpen, onCloseCreditCard, handleSubmit, name, onNameChange, loading } = props;
  const [cardNumberValid, setCardNumberValid] = useState<boolean>(false);
  const [cardExpiryValid, setCardExpiryValid] = useState<boolean>(false);
  const [cardCvcValid, setCardCvcValid] = useState<boolean>(false);

  useEffect(() => {
    setCardCvcValid(false);
    setCardExpiryValid(false);
    setCardCvcValid(false);
  }, [modalOpen]);

  const onCardNumberChanged = (event: any) => {
    setCardNumberValid(event.complete);
  };

  const onCardExpiryChanged = (event: any) => {
    setCardExpiryValid(event.complete);
  };

  const onCardCvcChanged = (event: any) => {
    setCardCvcValid(event.complete);
  };

  const isDisabled =
    !cardCvcValid ||
    !cardExpiryValid ||
    !cardNumberValid ||
    name === undefined ||
    name.length === 0;

  const renderIcon = () => (
    <LockIcon
      style={{
        color: isDisabled ? COLORS.BUTTON_DISABLED_TEXT : COLORS.BLACK,
        ...styles.icon,
      }}
    />
  );

  return (
    <Modal open={modalOpen}>
      <View style={styles.modalView}>
        <View style={styles.container1}>
          <View style={styles.modalContainer}>
            <View style={styles.modalHeader}>
              <TouchableOpacity style={styles.closeButton} onPress={onCloseCreditCard}>
                <View style={styles.closeWrapper}>
                  <Image source={Assets.svgs.general.close} style={styles.close} />
                </View>
              </TouchableOpacity>
              <CustomText size={20} bold>
                Credit / Debit Card
              </CustomText>

              <Image source={Assets.svgs.cc.brands} style={styles.brands} />
            </View>
            <View style={styles.splitLine3} />
            <form onSubmit={handleSubmit}>
              <View style={styles.nameWrapper}>
                <TextInput
                  style={styles.nameInput}
                  placeholder="Name"
                  value={name}
                  onChangeText={onNameChange}
                  placeholderTextColor={COLORS.PAY_INACTIVE}
                />
              </View>
              <View style={styles.elementWrapper}>
                <CardNumberElement
                  onChange={onCardNumberChanged}
                  options={{
                    style: {
                      base: styles.stripeElementStyle,
                      invalid: styles.stripeElementInvalidStyle,
                    },
                    showIcon: true,
                  }}
                />
              </View>
              <View style={styles.expCvc}>
                <View style={[styles.elementWrapper, styles.leftElement]}>
                  <CardExpiryElement
                    onChange={onCardExpiryChanged}
                    options={{
                      style: {
                        base: styles.stripeElementStyle,
                        invalid: styles.stripeElementInvalidStyle,
                      },
                    }}
                  />
                </View>
                <View style={[styles.elementWrapper, styles.rightElement]}>
                  <CardCvcElement
                    onChange={onCardCvcChanged}
                    options={{
                      style: {
                        base: styles.stripeElementStyle,
                        invalid: styles.stripeElementInvalidStyle,
                      },
                    }}
                  />
                </View>
              </View>

              <PayDisclaimer />

              <Button
                style={styles.button}
                disabled={isDisabled}
                type={EBUTTONTYPE.SUBMIT}
                title="Start 7-Day Trial"
                renderIcon={renderIcon}
                onPress={handleSubmit}
                loading={loading}
              />
            </form>
          </View>
        </View>
        <Toaster
          toastOptions={{
            style: styles.toast,
          }}
        />
      </View>
    </Modal>
  );
};
