import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { COLORS } from '../../../utils/Theme';
import { CustomText } from '../../CustomText';

const useStyles = (): any => {
  return {
    container: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
    },
    line: {
      height: 1,
      backgroundColor: COLORS.PAY_INACTIVE,
      flex: 1,
    },
    text: {
      marginHorizontal: 5,
    },
  };
};

interface IOrSplitterProps {
  style?: any;
}

export const OrSplitter: FunctionComponent<IOrSplitterProps> = (props) => {
  const styles = useStyles();
  const { style } = props;

  return (
    <View style={[styles.container, style]}>
      <View style={styles.line} />
      <CustomText style={styles.text} size={12}>
        OR
      </CustomText>
      <View style={styles.line} />
    </View>
  );
};
