import { PaymentIntent } from '@stripe/stripe-js';
import mixpanel from 'mixpanel-browser';
import * as qs from 'qs';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { EContentType } from '../enums/EContentType';
import { ANALYTICS_EVENTS, campaignParams } from '../utils/Analytics';
import { data } from '../utils/Data';
import { TRIAL_TYPES } from '../utils/Pricing';
import { getCookie } from '../utils/Utils';

interface OnboardingContextType {
  customer: any;
  setCustomer: (customer: any) => void;
  paymentIntent: PaymentIntent | undefined;
  setPaymentIntent: (paymentIntent: PaymentIntent) => void;
  subscription: any | undefined;
  setSubscription: (subscription: any) => void;
  setupIntent: any | undefined;
  setSetupIntent: (setupIntent: any) => void;
  mixpanelAnon: string | undefined;

  getCurrentStep: () => any;
  getCurrentStepExtended: () => any;
  gotoNext: (values?: any) => any;
  goBack: () => any;
  trialPriceType: TRIAL_TYPES | undefined;
  setTrialPriceType: (type: TRIAL_TYPES) => void;
  headerVisible: boolean;
  setHeaderVisible: (header: boolean) => void;
  comesFromEmail: boolean;
  searchParams: any;
  setSearchParams: (params: any) => void;
  getFBC: () => any;
  getFBP: () => any;
}

const OnboardingContext = createContext<OnboardingContextType>({} as OnboardingContextType);

export const OnboardingProvider = (props: any) => {
  const value = useOnboardingProvider();
  return <OnboardingContext.Provider value={value}>{props.children}</OnboardingContext.Provider>;
};

export const useOnboarding = () => {
  return useContext(OnboardingContext);
};

const START_INDEX = 0;
const SLIDER_INDEX = 38;

const useOnboardingProvider = (): OnboardingContextType => {
  const fromEmail = () => {
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    return params.cid !== undefined;
  };

  const { search } = useLocation();
  const [index, setIndex] = useState<number>(
    qs.parse(search, { ignoreQueryPrefix: true }).cid
      ? SLIDER_INDEX
      : qs.parse(search, { ignoreQueryPrefix: true }).secondPage
      ? 1
      : START_INDEX,
  );
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>();
  const [subscription, setSubscription] = useState<any>();
  const [setupIntent, setSetupIntent] = useState<any>();
  const [customer, setCustomer] = useState<any>({
    id: qs.parse(search, { ignoreQueryPrefix: true }).cid,
  });
  const [trialPriceType, setTrialPriceType] = useState<TRIAL_TYPES>();
  const [headerVisible, setHeaderVisible] = useState<boolean>(false);
  const [comesFromEmail] = useState<boolean>(fromEmail());
  const [searchParams, setSearchParams] = useState<any>();
  const [mixpanelAnon, setMixpanelAnon] = useState<string>(
    qs.parse(search, { ignoreQueryPrefix: true }).anon ? uuidv4() : undefined,
  );

  useEffect(() => {
    const params = qs.parse(search, { ignoreQueryPrefix: true });

    if (params.cid) {
      mixpanel.identify(params.cid);
    } else if (params.anon) {
      mixpanel.identify(params.anon);
      setMixpanelAnon(mixpanel.get_distinct_id());
      mixpanel.track(ANALYTICS_EVENTS.REDIRECTED);
    } else {
      setMixpanelAnon(mixpanel.get_distinct_id());
    }

    campaignParams();

    setSearchParams(params);
  }, []);

  const getFBC = () => {
    if (searchParams.fbclid) {
      return searchParams.fbclid;
    }

    return getCookie('_fbc');
  };

  const getFBP = () => {
    return getCookie('_fbp');
  };

  const getCurrentStepExtended = () => {
    let startIdx = index;
    let node;

    while (node === undefined) {
      node = (data.map[startIdx] as any).nodes.find((n: any) => {
        if (
          comesFromEmail &&
          n &&
          n.screen &&
          (n.screen.type === 'subscription-plan' || n.screen.type === 'skip-trial')
        ) {
          return false;
        }

        if (n.conditions) {
          const keys = Object.keys(n.conditions);
          if (keys.length > 0) {
            return keys.every((key) => {
              // eslint-disable-next-line no-undef
              return localStorage.getItem(key) == n.conditions[key]; // eslint-disable-line eqeqeq
            });
          }
        }

        return true;
      });

      if (node === undefined) {
        startIdx = startIdx + 1;
      }
    }

    setIndex(startIdx);

    return {
      step: node,
      group: (data.map[startIdx] as any).group,
      idx: (data.map[startIdx] as any).idx,
      total: (data.map[startIdx] as any).total,
    };
  };

  const getCurrentStep = (idx = index) => {
    let startIdx = idx;
    let node;

    while (node === undefined) {
      node = (data.map[startIdx] as any).nodes.find((n: any) => {
        if (n.conditions) {
          const keys = Object.keys(n.conditions);
          if (keys.length > 0) {
            return keys.every((key) => {
              // eslint-disable-next-line no-undef
              return localStorage.getItem(key) == n.conditions[key]; // eslint-disable-line eqeqeq
            });
          }
        }

        return true;
      });

      if (node === undefined) {
        startIdx = startIdx + 1;
      }
    }

    setIndex(startIdx);

    return node;
  };

  const gotoNext = (values?: any) => {
    if (values) {
      const step = getCurrentStep();
      if (step.screen.eventKey || step.screen.valueKey) {
        // eslint-disable-next-line no-undef
        localStorage.setItem(
          step.screen.valueKey || step.screen.eventKey,
          step.screen.multiple ? values : values[0],
        );
      }
    }
    setIndex(index + 1);
  };

  const goBack = () => {
    if (index > 0) {
      let startIdx = index - 1;
      let node;

      while (node === undefined) {
        node = (data.map[startIdx] as any).nodes.find((n: any) => {
          if (n.screen.contentType === EContentType.TIMEBAR) {
            return false;
          }

          if (n.conditions) {
            const keys = Object.keys(n.conditions);
            if (keys.length > 0) {
              return keys.every((key) => {
                // eslint-disable-next-line no-undef
                return localStorage.getItem(key) == n.conditions[key]; // eslint-disable-line eqeqeq
              });
            }
          }

          return true;
        });

        if (node === undefined) {
          startIdx = startIdx - 1;
        }
      }

      const newIndex = startIdx >= 0 ? startIdx : 0;
      setIndex(newIndex);
    } else {
      setIndex(0);
    }
  };

  return {
    getCurrentStep,
    gotoNext,
    goBack,

    mixpanelAnon,
    customer,
    setCustomer,
    paymentIntent,
    setPaymentIntent,
    subscription,
    setSubscription,
    trialPriceType,
    setTrialPriceType,
    getCurrentStepExtended,
    headerVisible,
    setHeaderVisible,
    comesFromEmail,
    setupIntent,
    setSetupIntent,
    searchParams,
    setSearchParams,
    getFBC,
    getFBP,
  };
};
