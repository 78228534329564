import React, { FunctionComponent } from 'react';
import { View } from 'react-native';

const useStyles = (): any => {
  return {
    container: {
      width: '100%',
      minHeight: '100%',
    },
  };
};

interface IBackgroundProps {}

export const Background: FunctionComponent<IBackgroundProps> = (props) => {
  const styles = useStyles();

  return <View style={styles.container}>{props.children}</View>;
};
