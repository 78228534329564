import { Linking, View } from 'react-native';
import React, { FunctionComponent, useEffect } from 'react';
import lottie from 'lottie-web';

import { Button } from '../Button';
import { CustomText } from '../CustomText';
import { Assets } from '../../utils/Assets';
import { useOnboarding } from '../../context/OnboardingContext';
import { IStep } from '../../interfaces/IStep';
import { useLayout } from '../../context/LayoutContext';
import { LINKS } from '../../utils/Constants';
import { ANALYTICS_EVENTS, iOSversion, trackEvent } from '../../utils/Analytics';
import { useRedirect } from '../../hooks/redirect.hook';
import mixpanel from 'mixpanel-browser';

const useStyles = (): any => {
  const { WSPACING, WP } = useLayout();
  const moonWidth = WP(90) - 40;
  const moonHeight = moonWidth;
  const marginMoon = -(moonHeight / 5);

  return {
    welcomestep: {
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: WSPACING(2),
      height: '100%',
    },
    buttonContainer: {
      width: '100%',
      marginBottom: 25,
      marginTop: 50,
    },
    title: {
      textAlign: 'center',
      letterSpacing: 0.6,
    },
    animation: {
      marginTop: 15,
      marginBottom: 15,
    },
    subTitle: {
      textAlign: 'center',
      marginBottom: 10,
    },
    moon: {
      width: moonWidth,
      height: moonHeight,
      marginTop: marginMoon,
      marginBottom: marginMoon,
    },
    legalText: {
      marginHorizontal: 20,
    },
    linkText: {
      textDecorationLine: 'underline',
    },
  };
};

interface IWelcomeStepProps {
  step: IStep;
}

export const WelcomeStep: FunctionComponent<IWelcomeStepProps> = (props) => {
  const styles = useStyles();
  const { step } = props;
  const { gotoNext, setHeaderVisible } = useOnboarding();
  const { isWeb, count, setCount, handleRedirectToBrowser } = useRedirect();

  useEffect(() => {
    setHeaderVisible(false);
  }, [step]);

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#lottie')!,
      animationData: Assets.animations.loadingMoon,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      initialSegment: [11, 600],
    });
    try {
      trackEvent(ANALYTICS_EVENTS.VIEWCONTENT);
      fbq('track', 'ViewContent');

      trackEvent(ANALYTICS_EVENTS.IOS_VERSION, { version: iOSversion() });
    } finally {
    }
  }, []);

  const onPress = () => {
    if (count === 1 || !isWeb || iOSversion() >= 15) {
      gotoNext();

      try {
        mixpanel.track(ANALYTICS_EVENTS.NOT_REDIRECTED);
      } catch (error) {
        console.log(error);
      }

      return;
    }

    if (isWeb) {
      setCount(1);
      setTimeout(() => handleRedirectToBrowser(), 100);

      return;
    }
  };

  const onEULAPressed = () => {
    Linking.openURL(LINKS.EULA);
  };

  const onPrivacyPressed = () => {
    Linking.openURL(LINKS.PRIVACY);
  };

  const onSupportPressed = () => {
    Linking.openURL(LINKS.SUPPORT);
  };

  return (
    <View style={styles.welcomestep}>
      <div style={styles.moon} id="lottie" />
      <CustomText style={styles.subTitle} medium size={13}>
        1-Minute Personal Assessement
      </CustomText>
      <CustomText style={styles.title} size={20} bold>
        Understand Yourself and Improve Relationships With Astrology
      </CustomText>
      <View style={styles.buttonContainer}>
        <Button title="Take test" onPress={onPress} />
      </View>
      <CustomText textAlign="center" style={styles.legalText} size={11} noHTML>
        By continuing you agree to our{' '}
        <CustomText noHTML medium style={styles.linkText} onPress={onEULAPressed}>
          EULA
        </CustomText>{' '}
        and{' '}
        <CustomText noHTML medium style={styles.linkText} onPress={onPrivacyPressed}>
          Privacy Notice
        </CustomText>
        . Have a question? Reach our friendly support team{' '}
        <CustomText noHTML medium style={styles.linkText} onPress={onSupportPressed}>
          here
        </CustomText>
        .
      </CustomText>
    </View>
  );
};
