export const COLORS = {
  TABBAR: '#151622',
  TABBAR_SPLIT: '#2f3144',
  BACKGROUND_SOLID: '#151622',
  TEXT_INACTIVE: '#9eadbb',
  TEXT_GRAY: '#90bac5',
  TEXT_BLUE: '#a0d7e7',
  HOROSCOPE_BUTTONS_BACKGROUND: '#151622',
  HOROSCOPE_TEXT: '#acb4c5',
  ENHANCEMENT_COLOR: '#83bccf',
  WHITE: '#fff',
  BLACK: '#000',
  BLUE: '#9FD7E7',
  PRIMARY: '#9FD7E7',
  SECONDARY: '#213054',
  HEADER: '#03274A',
  BACKGROUND_TOP: '#03274A',
  BACKGROUND_BOTTOM: 'rgba(0,19,42,0)',
  COMPATABILITY_SWITCH_SELECTOR_BORDER: '#2f394b',
  COMPATABILITY_SWITCHER: 'rgb(92,104,136)',
  COMPATABILITY_PLUS: '#2f394b',
  COMPATIBILITY_RESULT_HEADER_TEXT: '#fff',
  COMPATIBILITY_RESULT_HEADER_PLUS: '#b5cbd5',
  COMPATABILITY_ZODIAC: '#202734',
  COMPATIBILITY_ZODIAC_YOU: '#114c6d',
  COMPATABILITY_ZODIAC_TEXT: '#93b2d4',
  COMPATABILITY_PERCENTAGE_PRIMARY: '#a0d7e7',
  COMPATABILITY_PERCENTAGE_SECONDARY: '#2f394b',
  COMPATIBILITY_STATS_TILE_BACKGROUND: '#114c6d',
  ZODIAC_TILE: '#232630',
  CLOSE_BUTTON: '#fff',
  MODAL_BACKDROP: '#08090b',
  BOTTOM_SHEET: '#212835',
  BOTTOM_SHEET_CLOSELINE: '#3c4557',
  BUTTON_DISABLED: '#4c455f74',
  BUTTON_DISABLED_TEXT: '#7b7c8d',
  BUTTON_PRIMARY: '#1D607B',
  BUTTON_PRIMARY_END: '#2a7489',
  BUTTON_PRIMARY_START: '#114c6d',
  BUTTON_SECONDARY: '#202734',
  HEADER_BACKGROUND: '#212835',
  CONTENT_TEXT: 'rgb(187,198,222)',
  BUTTON_WHITE: '#fff',
  BUTTON_WHITE_TEXT: '#14203d',
  INTRO_GRADIENT: ['#082436', '#062336'],
  PAYWALL_GRADIENT: ['#111244', '#01010a'],
  TRANSPARENT_GRADIENT: ['transparent', 'transparent'],
  INTRO_TEXT: '#acb4c5',
  TRANSPARENT: 'transparent',
  WHITE_VERY_TRANSPARENT: '#ffffff33',
  WHITE_TRANSPARENT: '#ffffffbb',
  INPUT_TEXT: '#fff',
  LOADING_SPINNER_BACKGROUND: '#3583ca',
  LOADING_TEXT: '#93b2d4',
  LOADING_BACKGROUND: '#9FD7E7',
  STEP_INACTIVE: '#ffffff99',
  STEP_ACTIVE: '#3583ca',
  PREMIUM_NUMBER_LOCKED_TILE: 'rgb(34,49,83)',
  NUMEROSCOPE_SPLIT_LINE: 'rgb(41,52,80)',
  SMALL_BUTTON_BACKGROUND: '#ffffff33',
  CAPTURING_SPINNER_BACKGROUND: '#213054aa',
  MODAL_CONTENT_BACKGROUND: '#14203d',
  ENHANCEMENT_MODAL_BACKGROUND: '#114c6d',
  MODAL_SUB_TITLE: '#bbc6de',
  LEGAL_SETTINGS_SEPERATOR: '#293450',
  FEEDBACK_INPUT_BACKGROUND: '#14203d',
  PROFILE_LIST_ITEM_LABEL: '#93b2d4',
  PAYWALL_HEADER_TINT: '#93bddd',
  PAYWALL_HEADER_BACKGROUND: '#203054',
  PAYWALL_SECONDARY_TEXT: '#93b2d4',
  PAYWALL_TITLE_COLOR: '#feffe1',
  MOON: '#f2f0d7',
  BUTTON_DARK_COLOR: '#123449',
  PAYWALL_FEATURE_COLOR: '#acb4c5',
  AFFIRMATION_VIOLET: '#3c2f59',
  AFFIRMATION_GREEN: '#063e4a',
  AFFIRMATION_DARK: '#14152A',
  SPECIAL_OFFER: '#9FD7E7',
  BLUE_BUY: 'rgb(39,161,250)',
  BUY_PLAN: '#f2f0d7',

  PLACEHOLDER_COLOR: '#ddd',
  PLACEHOLDER_INACTIVE_COLOR: '#999',
  NOTIFICATION_BADGE_COLOR: '#F1745E',
  ERROR: '#F1745E',
  NOTIFICATION_BACKGROUND: 'rgba(43,49,70,255)',

  CHAT_BUBBLE_BACKGROUND: '#202734',
  CHAT_BUBBLE_ME_BACKGROUND: '#114c6d',
  CHAT_TEXT_CONTRAST_COLOR: '#9FD7E7',
  CHAT_COMPOSER_BACKGROUND: '#202734',
  CHAT_TOOLBAR_BACKGROUND: '#151622',
  CHAT_PLACEHOLDER_COLOR: '#9FD7E7',
  CHAT_PLACEHOLDER_INACTIVE_COLOR: '#9FD7E799',
  CHAT_REACTION_ITEM_BACKGROUND: '#151622',
  CHAT_REACTION_ITEM_BORDER: '#202734',
  CHAT_REACTION_BUTTON_BORDER: '#2f3144',
  MATCHUP_DONE_GRADIENT: ['#a0d7e7bf', '#114c6d00'],
  MATCHUP_DONE_BACKGROUND: '#9FD7E7',
  MATCHUP_DONE_SUBTITLE: '#b3d5df',

  REACTIVATE_TRIAL_BACKGROUND: '#072436',

  DATE_SELECT_PRIMARY: '#3c4557',
  TIME_SELECT_SELECTED: '#9FD7E7',
  TIME_SELECT_UNSELECTED: '#3c4557',

  QUOTE_CARD_BACKGROUND_COLOR: '#3c4557',
  PROGRESS_BAR_BACKGROUND: '#202734',
  TIMEBAR_LABEL: '#9FD7E7',
  SLIDER_GRADIENT: ['#287187', '#114c6d'],
  PAY_INACTIVE: '#bfbfbf',
  SEPARATOR_LINE: '#3c4557',
  DIVIDER_COLOR: '#3c4557',
  DISCOUNT_BACKGROUND: '#feffe1',
  PAYWALL_TEXT_COLOR: '#acb4c5',
  DISCOUNT_HIGHTLIGHT_COLOR: '#114c6d',
  FEATURED_IN_GRADIENT: ['transparent', '#114c6d'],
  REVIEW_BACKGROUND: '#202734',
  SHOW_MORE: '#a0d7e7',

  GROUP_1: '#9FD7E7',
  GROUP_2: '#F1745E',
  GROUP_3: '#04A777',
};
