export const DAYS_IN_MONTH = {
  28: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28,
  ],
  29: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29,
  ],
  30: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30,
  ],
  31: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31,
  ],
};

export const BUILDING_GUIDANCE_LABEL = [
  { 1: 'Your Profile', 2: 'Personality Traits', 3: 'Relationship Pattern' },
  { 1: 'Your Profile', 2: 'Your Partner’s Profile', 3: 'Relationship & Personality Patterns' },
];

export const LINKS = {
  SUPPORT: 'mailto:support@spirit-horoscope.app',
  EULA: 'https://www.spirit-horoscope.app/landing_page/terms-conditions.html',
  PRIVACY: 'https://www.spirit-horoscope.app/landing_page/privacy-policy.html',
};

export const HIGHTLIGHT_LIST = [
  [
    '1:1 advice from your own astrologer',
    'Finding the most compatible partner',
    'Insights into your relationship patterns, and emotional and sexual needs',
    'Better understanding of yourself',
  ],
  [
    '1:1 advice from your own astrologer',
    'Improving your relationship with your partner',
    'Insights into your relationship patterns, and emotional and sexual needs',
    'Better understanding of yourself and your loved one',
  ],
];
