import React, { FunctionComponent, useEffect } from 'react';
import { Linking, View } from 'react-native';
import lottie from 'lottie-web';

import { Background } from './Background';
import { Button, EBUTTONTYPE } from './Button';
import { CustomText } from './CustomText';
import { Assets } from '../utils/Assets';
import { useLayout } from '../context/LayoutContext';
import { useParams } from 'react-router-dom';

const useStyles = (): any => {
  const { WSPACING, WP } = useLayout();
  const moonWidth = WP(90) - 40;
  const moonHeight = moonWidth;
  const marginMoon = -(moonHeight / 5);
  return {
    welcomestep: {
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: WSPACING(2),
      height: '100%',
    },
    buttonContainer: {
      width: '100%',
      marginBottom: 25,
      marginTop: 20,
    },
    title: {
      textAlign: 'center',
      letterSpacing: 0.6,
    },
    animation: {
      marginTop: 15,
      marginBottom: 15,
    },
    subTitle: {
      textAlign: 'center',
      marginBottom: 10,
    },
    moon: {
      width: moonWidth,
      height: moonHeight,
      marginTop: marginMoon,
      marginBottom: marginMoon,
    },
    container: { marginHorizontal: 20, height: '100%' },
    content: { flex: 1 },
    button: { marginTop: 10 },
  };
};

interface IActivationProps {}

export const Activation: FunctionComponent<IActivationProps> = (props) => {
  const styles = useStyles();
  let { id } = useParams();

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#lottie')!,
      animationData: Assets.animations.loadingMoon,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      initialSegment: [11, 600],
    });
  }, []);

  const onActivate = () => {
    Linking.openURL(`spirit://${id}`);
  };

  return (
    <Background>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.welcomestep}>
            <div style={styles.moon} id="lottie" />
            <CustomText style={styles.title} size={18} bold>
              The Spirit app must be installed on your device
            </CustomText>
            <View style={styles.buttonContainer}>
              <Button
                style={styles.button}
                type={EBUTTONTYPE.MOON}
                title="Activate"
                onPress={onActivate}
              />
            </View>
          </View>
        </View>
      </View>
    </Background>
  );
};
