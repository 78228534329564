import React, { FunctionComponent } from 'react';
import { EStepType } from '../../enums/EStepType';
import { IStep } from '../../interfaces/IStep';
import { BuildingGuidanceStep } from './BuildingGuidanceStep';
import { FormStep } from './FormStep';
import { OtherStep } from './OtherStep';
import { SpiritDownloadStep } from './SpiritDownloadStep';
import { SubscriptionInfoStep } from './SubscriptionInfoStep';
import { SubscriptionPayStep } from './SubscriptionPayStep/SubscriptionPayStep';
import { SubscriptionPlanStep } from './SubscriptionPlanStep';
import { SubscriptionSkipTrialStep } from './SubscriptionSkipTrialStep';
import { UnknownStep } from './UnknownStep';
import { WelcomeStep } from './WelcomeStep';

interface IStepProps {
  step: IStep;
}

export const Step: FunctionComponent<IStepProps> = (props) => {
  const { step } = props;

  const renderStep = () => {
    if (step.screen && step.screen.type === EStepType.FORM) {
      return <FormStep step={step} />;
    } else if (step.screen && step.screen.type === EStepType.BUILDING_GUIDANCE) {
      return <BuildingGuidanceStep step={step} />;
    } else if (step.screen && step.screen.type === EStepType.WELCOME) {
      return <WelcomeStep step={step} />;
    } else if (step.screen && step.screen.type === EStepType.SUBSCRIPTION_PLAN) {
      return <SubscriptionPlanStep step={step} />;
    } else if (step.screen && step.screen.type === EStepType.SUBSCRIPTION_INFO) {
      return <SubscriptionInfoStep step={step} />;
    } else if (step.screen && step.screen.type === EStepType.PAYMENT_UPDATOR) {
      return <SubscriptionPayStep step={step} />;
    } else if (step.screen && step.screen.type === EStepType.SKIP_TRIAL) {
      return <SubscriptionSkipTrialStep step={step} />;
    } else if (step.screen && step.screen.type === EStepType.DOWNLOAD) {
      return <SpiritDownloadStep step={step} />;
    } else if (step.screen && step.screen.type === EStepType.OTHER) {
      return <OtherStep step={step} />;
    }

    return <UnknownStep step={step} />;
  };

  return renderStep();
};
