export enum EContentType {
  POLL = 'poll',
  INFO = 'info',
  DATE = 'date',
  TIME = 'time',
  PLACE = 'place',
  SPIRIT = 'spirit',
  EMAIL = 'email',
  SLIDER = 'slider',
  SUBSCRIPTION_PLAN = 'subscription_plan',
  TIMEBAR = 'timebar',
}
