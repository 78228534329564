import React, { FunctionComponent } from 'react';
import { TouchableOpacity } from 'react-native';
import { COLORS } from '../../../utils/Theme';
import { CustomText } from '../../CustomText';
import { TDaytime } from '../FormTimeStep';

const useStyles = (): any => {
  return {
    button: {
      height: '100%',
      width: 65,
      borderRadius: 10,
      marginRight: 6,
      justifyContent: 'center',
      alignItems: 'center',
    },
    selected: {
      backgroundColor: COLORS.TIME_SELECT_SELECTED,
    },
    unselected: {
      backgroundColor: COLORS.TIME_SELECT_UNSELECTED,
    },
  };
};

interface ITimeButtonProps {
  selected: boolean;
  title: string;
  onPress: (value: TDaytime) => void;
  type: 'am' | 'pm';
}

export const TimeButton: FunctionComponent<ITimeButtonProps> = (props) => {
  const styles = useStyles();

  const { selected, title, type, onPress } = props;

  return (
    <TouchableOpacity
      style={[styles.button, selected ? styles.selected : styles.unselected]}
      onPress={() => onPress(type)}>
      <CustomText size={16} color={selected ? COLORS.BLACK : COLORS.WHITE}>
        {title}
      </CustomText>
    </TouchableOpacity>
  );
};
