import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useLayout } from '../../context/LayoutContext';
import { COLORS } from '../../utils/Theme';
import { CustomText } from '../CustomText';

const useStyles = (): any => {
  const { WP } = useLayout();

  return {
    container: {
      width: WP(100),
      height: 55,
      backgroundColor: COLORS.BUTTON_SECONDARY,
      marginLeft: -30,
      justifyContent: 'center',
      alignItems: 'center',
    },
    container2: {
      width: 200,
      height: 36,
      backgroundColor: COLORS.BACKGROUND_SOLID,
      borderRadius: 30,
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
    },
    container3: {
      height: 30,
      width: 30,
      borderRadius: 30,
      marginLeft: 3,
      backgroundColor: COLORS.BUTTON_SECONDARY,
      justifyContent: 'center',
      alignItems: 'center',
    },
    emailContainer: { marginHorizontal: 10, width: 150 },
  };
};

interface IEmailInfoProps {
  email: string;
}

export const EmailInfo: FunctionComponent<IEmailInfoProps> = (props) => {
  const styles = useStyles();
  const { email } = props;

  return (
    <View style={styles.container}>
      <View style={styles.container2}>
        <View style={styles.container3}>
          <CustomText bold size={15}>
            {email[0].toUpperCase()}
          </CustomText>
        </View>
        <View style={styles.emailContainer}>
          <CustomText noHTML numberOfLines={1} ellipsizeMode={'tail'} bold size={14}>
            {email}
          </CustomText>
        </View>
      </View>
    </View>
  );
};
