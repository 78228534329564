import React, { FunctionComponent } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { ChevronLeft } from '@material-ui/icons';

import { useLayout } from '../../../context/LayoutContext';
import { useOnboarding } from '../../../context/OnboardingContext';
import { COLORS } from '../../../utils/Theme';
import { getGroupInfo } from '../../../utils/Utils';
import { CustomText } from '../../CustomText';
import { ProgressStep } from './ProgressStep';
import { ProgressCircle } from './ProgressCircle';

const useStyles = (): any => {
  const { WP } = useLayout();

  return {
    container: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
    },
    progress: {
      width: WP(100) - 40,
      flexDirection: 'row',
      alignItems: 'center',
    },
    secondContainer: {
      width: WP(100) - 40,
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
    },
    splitLine: {
      width: WP(100),
      height: 1,
      backgroundColor: COLORS.BUTTON_SECONDARY,
    },
    back: {
      position: 'absolute',
      left: 0,
    },
    icon: { fill: COLORS.WHITE, width: 25, height: 25 },
  };
};

interface IProgressHeaderProps {
  stepExtended: any;
}

export const ProgressHeader: FunctionComponent<IProgressHeaderProps> = (props) => {
  const { stepExtended } = props;
  const styles = useStyles();
  const { goBack, headerVisible } = useOnboarding();

  const width = 4;
  const size = 16;

  const groupInfo = getGroupInfo(stepExtended.group);

  const onBackPressed = () => {
    goBack();
  };

  if (!headerVisible) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.progress}>
        <ProgressCircle
          circleSize={size}
          primaryColor={COLORS.GROUP_1}
          secondaryColor={COLORS.BUTTON_SECONDARY}
          finished
        />
        <ProgressStep
          group={1}
          stepExtended={stepExtended}
          circleSize={size}
          strokeWidth={width}
          primaryColor={COLORS.GROUP_1}
          secondaryColor={COLORS.BUTTON_SECONDARY}
        />
        <ProgressStep
          group={2}
          stepExtended={stepExtended}
          circleSize={size}
          strokeWidth={width}
          primaryColor={COLORS.GROUP_2}
          secondaryColor={COLORS.BUTTON_SECONDARY}
        />
        <ProgressStep
          group={3}
          stepExtended={stepExtended}
          circleSize={size}
          strokeWidth={width}
          primaryColor={COLORS.GROUP_3}
          secondaryColor={COLORS.BUTTON_SECONDARY}
        />
      </View>

      <View style={styles.secondContainer}>
        <TouchableOpacity style={styles.back} onPress={onBackPressed}>
          <ChevronLeft style={styles.icon} />
        </TouchableOpacity>
        <CustomText size={14} color={groupInfo.color} bold>
          {groupInfo.name}
        </CustomText>
      </View>
      <View style={styles.splitLine} />
    </View>
  );
};
