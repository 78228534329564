import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';

import { IStep } from '../../interfaces/IStep';
import { Button } from '../Button';
import { CustomText } from '../CustomText';
import { useOnboarding } from '../../context/OnboardingContext';
import { useLayout } from '../../context/LayoutContext';
import { format } from '../../utils/Utils';
import { TimeButton } from './FormTimeStep/TimeButton';
import { CustomTimeSelect } from './FormTimeStep/CustomTimeSelect';
import { getHourOptions, getMinuteOptions } from '../../utils/SelectHelper';
import moment from 'moment';

const useStyles = (): any => {
  const { WP } = useLayout();

  return {
    container: {
      height: '100%',
      alignItems: 'center',
      marginHorizontal: 10,
    },
    buttonContainer: {
      marginTop: 20,
      alignItems: 'center',
      marginBottom: 40,
    },
    button: {
      minWidth: 100,
      width: WP(50),
    },
    title: {
      textAlign: 'center',
    },
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    image: {
      maxWidth: 250,
      maxHeight: 250,
    },
    subTitle: {
      marginTop: 10,
      marginBottom: 10,
      textAlign: 'center',
    },
    inputWrapper: {
      flexDirection: 'row',
      width: '100%',
      justifyItems: 'center',
    },
  };
};

interface IFormTimeStepProps {
  step: IStep;
}

export type TDaytime = 'none' | 'am' | 'pm';

export const FormTimeStep: FunctionComponent<IFormTimeStepProps> = (props) => {
  const styles = useStyles();
  const { step } = props;
  const { gotoNext } = useOnboarding();
  const [minute, setMinute] = useState<number>(-1);
  const [hour, setHour] = useState<number>(-1);
  const [dayTime, setDayTime] = useState<TDaytime>('none');

  const onNext = () => {
    // eslint-disable-next-line no-undef
    const dateString = localStorage.getItem('date-of-birth');

    if (dateString) {
      const newDate = moment.unix(parseInt(dateString, 10) / 1000);
      newDate.hour(dayTime === 'pm' ? hour + 12 : hour).minute(minute);

      gotoNext([newDate.utc().valueOf()]);
    } else {
      gotoNext([moment().utc().valueOf()]);
    }
  };

  const onTimeButtonPressed = (value: TDaytime) => {
    if (dayTime === value) {
      setDayTime('none');
    } else {
      setDayTime(value);
    }
  };

  return (
    <View style={styles.container}>
      <CustomText style={styles.title} bold size={15}>
        {format(step.screen.title)}
      </CustomText>
      <CustomText style={styles.subTitle} medium size={15}>
        {step.screen.subtitle}
      </CustomText>
      <View style={styles.inputWrapper}>
        <CustomTimeSelect
          label="HH"
          onChange={setHour}
          options={getHourOptions()}
          selected={hour}
        />
        <CustomTimeSelect
          label="MM"
          onChange={setMinute}
          options={getMinuteOptions()}
          selected={minute}
        />
        <TimeButton
          selected={dayTime === 'am'}
          type={'am'}
          title="AM"
          onPress={onTimeButtonPressed}
        />
        <TimeButton
          selected={dayTime === 'pm'}
          type={'pm'}
          title="PM"
          onPress={onTimeButtonPressed}
        />
      </View>
      <View style={styles.buttonContainer}>
        <Button
          style={styles.button}
          disabled={minute === -1 || hour === -1 || dayTime === 'none'}
          title="Next"
          onPress={onNext}
        />
      </View>
    </View>
  );
};
