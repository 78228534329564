import React, { FunctionComponent } from 'react';
import { useOnboarding } from '../../../context/OnboardingContext';
import { getTrialPrice, TRIAL_TYPES } from '../../../utils/Pricing';
import { CustomText } from '../../CustomText';

const useStyles = (): any => {
  return {
    disclaimer: { width: '100%', marginVertical: 10 },
  };
};

interface IPayDisclaimerProps {}

export const PayDisclaimer: FunctionComponent<IPayDisclaimerProps> = (props) => {
  const styles = useStyles();
  const { trialPriceType } = useOnboarding();
  const price = getTrialPrice(trialPriceType || TRIAL_TYPES.TRIAL_0);

  return (
    <CustomText noHTML style={styles.disclaimer} size={12} medium>
      You will be charged only{' '}
      <CustomText size={12} bold noHTML>
        {`$${price} for your 7-day trial.`}
      </CustomText>{' '}
      We'll{' '}
      <CustomText size={12} bold noHTML>
        email you a reminder
      </CustomText>{' '}
      before your trial period ends. Cancel anytime.
    </CustomText>
  );
};
