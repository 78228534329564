import React, { FunctionComponent, useState } from 'react';
import { View, Image, ImageBackground, TouchableOpacity } from 'react-native';

import { IStep } from '../../interfaces/IStep';
import { Button } from '../Button';
import { CustomText } from '../CustomText';
import { useOnboarding } from '../../context/OnboardingContext';
import { useLayout } from '../../context/LayoutContext';
import { format, ue } from '../../utils/Utils';
import { Assets } from '../../utils/Assets';
import { COLORS } from '../../utils/Theme';
import { Highlight } from './SubscriptionInfoStep/Hightlight';
import { HIGHTLIGHT_LIST } from '../../utils/Constants';
import { Review } from './SubscriptionInfoStep/Review';
// @ts-ignore
import LinearGradient from 'react-native-web-linear-gradient';
import { REVIEWS } from '../../utils/Reviews';

const useStyles = (): any => {
  const { WP } = useLayout();
  const MOUNTAINS_WIDTH = WP(100);
  const MOUNTAINS_HEIGHT = MOUNTAINS_WIDTH / 3.125;

  const FORBES_WIDTH = 90;
  const FORBES_HEIGHT = 90 / 2.67379679;
  const REF_HEIGHT = FORBES_HEIGHT * 0.9;

  return {
    container: {
      height: '100%',
      alignItems: 'center',
      marginHorizontal: 10,
    },
    buttonContainer: {
      height: 80,
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      width: WP(100) - 40,
    },
    title: {
      width: '100%',
    },
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    image: {
      maxWidth: 250,
      maxHeight: 250,
    },
    subTitle: {
      marginTop: 10,
      marginBottom: 10,
      letterSpacing: 0.2,
    },
    spiritImage: {
      width: 98,
      height: 35.7,
    },
    topContainer: {
      width: WP(100),
      height: 100,
      alignItems: 'center',
      justifyContent: 'center',
    },
    discountContainer: {
      backgroundColor: COLORS.DISCOUNT_BACKGROUND,
      width: WP(100),
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: '10%',
      paddingVertical: '4%',
      height: 70,
    },
    highlightTitle: {
      marginTop: 30,
      marginBottom: 12,
    },
    showMoreButton: { alignSelf: 'flex-end', marginTop: 6 },
    mountainsSvg: {
      width: MOUNTAINS_WIDTH,
      height: MOUNTAINS_HEIGHT,
      marginTop: -MOUNTAINS_HEIGHT / 3,
    },
    linearGradient: {
      alignItems: 'center',
    },
    forbes: {
      width: FORBES_WIDTH,
      height: FORBES_HEIGHT,
      tintColor: COLORS.GROUP_1,
      marginRight: 15,
    },
    techcrunch: {
      width: REF_HEIGHT * 5.71052632,
      height: REF_HEIGHT,
      tintColor: COLORS.GROUP_1,
    },
    mashable: {
      width: REF_HEIGHT * 4.5045045,
      height: REF_HEIGHT,
      tintColor: COLORS.GROUP_1,
    },
    refWrapper: {
      alignItems: 'center',
      marginTop: 15,
      flexDirection: 'row',
      justifyItems: 'center',
      marginBottom: 25,
    },
    featuredInText: {
      marginTop: 35,
    },
  };
};

interface ISubscriptionInfoStepProps {
  step: IStep;
}

export const SubscriptionInfoStep: FunctionComponent<ISubscriptionInfoStepProps> = (props) => {
  const styles = useStyles();
  const { step } = props;
  const { gotoNext } = useOnboarding();
  const [loadMore, setLoadMore] = useState<boolean>(false);

  // eslint-disable-next-line no-undef
  const relStatus = parseInt(localStorage.getItem(ue.RELATIONSHIP_STATUS) || '0', 10);
  // eslint-disable-next-line no-undef
  const gender = localStorage.getItem(ue.GENDER) || '0';

  // @ts-ignore
  const reviews = REVIEWS[gender][relStatus.toString()];

  const onNext = () => {
    gotoNext([]);
  };

  return (
    <View style={styles.container}>
      <View style={styles.discountContainer}>
        <CustomText
          noHTML
          style={styles.title}
          semibold
          color={COLORS.BLACK}
          size={14}
          textAlign="center">
          {'Special Offer:'}
          <CustomText noHTML style={styles.title} regular color={COLORS.BLACK} size={14}>
            {' Get 30% off + 7 days trial with code '}
            <CustomText noHTML style={styles.title} bold color={COLORS.BLUE_BUY} size={14}>
              {'SPIRIT30'}
            </CustomText>
          </CustomText>
        </CustomText>
      </View>
      <ImageBackground source={Assets.svgs.general.backgroundStars} style={styles.topContainer}>
        <Image source={Assets.svgs.general.spirit} style={styles.spiritImage} />
      </ImageBackground>
      <View style={styles.divider} />
      <CustomText style={styles.title} extrabold size={16}>
        {format(step.screen.title, false)}
      </CustomText>
      <CustomText style={styles.subTitle} regular color={COLORS.PAYWALL_TEXT_COLOR} size={15}>
        {step.screen.subtitle}
      </CustomText>
      <CustomText style={[styles.title, styles.highlightTitle]} extrabold size={16}>
        Highlights of your plan:
      </CustomText>
      {HIGHTLIGHT_LIST[relStatus].map((value, idx) => (
        <Highlight key={idx} content={value} />
      ))}
      <CustomText style={[styles.title, styles.highlightTitle]} extrabold size={16}>
        Customer reviews
      </CustomText>
      <Review review={reviews[0]} />
      <Review review={reviews[1]} />
      <Review review={reviews[2]} />

      {loadMore && (
        <>
          <Review review={reviews[3]} />
          <Review review={reviews[4]} />
        </>
      )}

      <TouchableOpacity style={styles.showMoreButton} onPress={() => setLoadMore(!loadMore)}>
        <CustomText medium color={COLORS.SHOW_MORE} size={14}>
          {!loadMore ? 'Show more' : 'Show less'}
        </CustomText>
      </TouchableOpacity>
      <LinearGradient
        style={styles.linearGradient}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
        colors={COLORS.FEATURED_IN_GRADIENT}>
        <CustomText size={15} style={styles.featuredInText}>
          As featured in
        </CustomText>
        <View style={styles.refWrapper}>
          <Image source={Assets.images.general.forbes} style={styles.forbes} />
          <Image source={Assets.images.general.mashable} style={styles.mashable} />
        </View>
        <Image source={Assets.images.general.techcrunch} style={styles.techcrunch} />
        <Image source={Assets.svgs.general.mountains} style={styles.mountainsSvg} />
      </LinearGradient>
      <View style={styles.buttonContainer}>
        <Button style={styles.button} title="Next" onPress={onNext} />
      </View>
    </View>
  );
};
