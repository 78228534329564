import { MuiThemeProvider, createTheme } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import ResizeObserver from 'resize-observer-polyfill';

import { Activation } from './src/components/Activation';
import { AppContainer } from './src/components/AppContainer';
import { Onboarding } from './src/components/onboarding/Onboarding';
import { LayoutProvider } from './src/context/LayoutContext';
import { OnboardingProvider } from './src/context/OnboardingContext';
import { loadFonts } from './src/utils/Assets';
import { COLORS } from './src/utils/Theme';

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

if (process.env.NODE_ENV === 'development') {
  mixpanel.init('dbd3dda7bb9cb308017391b7543b3621');
} else {
  mixpanel.init('28cf7b489d14bc71604a374fe11f9c1b', {
    api_host: 'https://proxy-wyngjsa4ra-uc.a.run.app',
  });
}

const stripePromise = loadStripe(process.env.STRIPE);

const styles = StyleSheet.create({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

const THEME = createTheme({
  palette: {
    error: {
      main: COLORS.ERROR,
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: 13,
  },
  overrides: {
    MuiMenu: {
      paper: {
        backgroundColor: COLORS.BUTTON_SECONDARY,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 12,
      },
    },
  },
});

export default function App() {
  useEffect(() => {
    loadFonts();
  }, []);

  return (
    <Router>
      <View style={styles.container}>
        <MuiThemeProvider theme={THEME}>
          <Elements
            options={{
              appearance: {
                theme: 'night',
              },
            }}
            stripe={stripePromise}>
            <LayoutProvider>
              <OnboardingProvider>
                <AppContainer>
                  <Switch>
                    <Route path="/activate/:id">
                      <Activation />
                    </Route>
                    <Route path="/">
                      <Onboarding />
                    </Route>
                  </Switch>
                </AppContainer>
              </OnboardingProvider>
            </LayoutProvider>
          </Elements>
        </MuiThemeProvider>
      </View>
    </Router>
  );
}
