import moment from 'moment';
import { DAYS_IN_MONTH } from './Constants';
export const getMonthsOptions = () => {
  return [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];
};

export const getDayOptions = (count?: 28 | 29 | 30 | 31) => {
  if (!count) {
    return DAYS_IN_MONTH[31].map((day: number) => ({
      value: day,
      label: day.toString(),
    }));
  } else {
    return DAYS_IN_MONTH[count].map((day: number) => ({
      value: day,
      label: day.toString(),
    }));
  }
};

export const getYearOptions = () => {
  let options = [];
  const currentYear = moment().year();
  for (let i = 0; i <= 110; i++) {
    options.push({ value: currentYear - i, label: `${currentYear - i}` });
  }
  return options;
};

export const getHourOptions = () => {
  return [
    { value: 0, label: '01' },
    { value: 1, label: '02' },
    { value: 2, label: '03' },
    { value: 3, label: '04' },
    { value: 4, label: '05' },
    { value: 5, label: '06' },
    { value: 6, label: '07' },
    { value: 7, label: '08' },
    { value: 8, label: '09' },
    { value: 9, label: '10' },
    { value: 10, label: '11' },
    { value: 11, label: '12' },
  ];
};

export const getMinuteOptions = () => {
  let options = [];
  for (let i = 0; i <= 59; i++) {
    options.push({ value: i, label: String(i).padStart(2, '0') });
  }
  return options;
};
