import mixpanel from 'mixpanel-browser';

function getQueryParam(url: any, param: any) {
  // Expects a raw URL
  param = param.replace(/[[]/, '[').replace(/[]]/, ']');
  var regexS = '[?&]' + param + '=([^&#]*)',
    regex = new RegExp(regexS),
    results = regex.exec(url);
  if (results === null || (results && typeof results[1] !== 'string' && results[1].length)) {
    return '';
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
  }
}

export function campaignParams() {
  var campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' '),
    kw = '',
    params = {},
    first_params = {};

  var index;
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      params[campaign_keywords[index] + ' [last touch]'] = kw;
    }
  }
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      first_params[campaign_keywords[index] + ' [first touch]'] = kw;
    }
  }

  mixpanel.people.set(params);
  mixpanel.people.set_once(first_params);
  mixpanel.register(params);
}

export const ANALYTICS_EVENTS = {
  COMPLETEREGISTRATION: 'complete_registration',
  SUBSCRIBE: 'subscribe',
  VIEWCONTENT: 'view_content',
  STARTTRIAL: 'start_trial',
  PURCHASE: 'purchase',
  EMAIL_0_DAY_TRIAL_OPENED: 'email_0_day_trial_openend',
  EMAIL_0_DAY_TRIAL_PURCHASED: 'email_0_day_trial_purchased',
  PAYMENT_METHOD: 'payment_method',
  ADDTOCART: 'add_to_cart',
  MOBILE_PAY_VISIBLE: 'mobile_pay_visible',
  SKIPPED_TRIAL: 'skipped_trial',
  IOS_VERSION: 'ios_version',
  REDIRECTING: 'redirecting',
  REDIRECTED: 'redirected',
  NOT_REDIRECTED: 'not_redirected',
};

export const enum EPAYMENTMETHODS {
  CC = 'cc',
  MOBILE_PAY = 'mobile_pay',
}

export const trackEvent = (name: string, properties?: any) => {
  mixpanel.track(name, properties);
};

export const trackPaymentMethod = (method: EPAYMENTMETHODS) => {
  try {
    trackEvent(ANALYTICS_EVENTS.PAYMENT_METHOD, { method });
  } finally {
  }
};

export function iOSversion(): number {
  let agent = window.navigator.userAgent,
    start = agent.indexOf('OS ');
  if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
    return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
  }
  return 0;
}
