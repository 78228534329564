import React, { FunctionComponent, useState } from 'react';
import { Linking, View } from 'react-native';

import { useLayout } from '../../context/LayoutContext';
import { useOnboarding } from '../../context/OnboardingContext';
import { IStep } from '../../interfaces/IStep';
import { LINKS } from '../../utils/Constants';
import { format } from '../../utils/Utils';
import { Button } from '../Button';
import { CustomText } from '../CustomText';
import { CustomTextField } from './CustomTextField';

const useStyles = (): any => {
  const { WP } = useLayout();

  return {
    container: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 10,
    },
    buttonContainer: {
      marginTop: 20,
      alignItems: 'center',
      marginBottom: 40,
    },
    button: {
      minWidth: 100,
      width: WP(50),
    },
    title: {
      textAlign: 'center',
      marginBottom: 25,
    },
    animation: {
      maxWidth: 250,
      maxHeight: 250,
    },
    image: {
      maxWidth: 250,
      maxHeight: 250,
    },
    subTitle: {
      marginTop: 15,
      marginBottom: 10,
      textAlign: 'center',
    },
    linkText: {
      textDecorationLine: 'underline',
    },
    legalText: {
      marginTop: 10,
    },
  };
};

interface IFormEmailStepProps {
  step: IStep;
}

export const FormEmailStep: FunctionComponent<IFormEmailStepProps> = (props) => {
  const styles = useStyles();
  const { step } = props;
  const { gotoNext, setCustomer, searchParams, getFBC, getFBP } = useOnboarding();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onEULAPressed = () => {
    Linking.openURL(LINKS.EULA);
  };

  const onPrivacyPressed = () => {
    Linking.openURL(LINKS.PRIVACY);
  };

  const onValueChange = (newValue: string) => {
    setError(false);
    setEmail(newValue);
  };

  const validate = (currentEmail: string) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (currentEmail.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const onNext = async () => {
    setIsLoading(true);
    try {
      if (validate(email)) {
        gotoNext(['temporarytest@gmail.com']);
        // // eslint-disable-next-line no-undef
        // const dob = Number(localStorage.getItem('date-of-birth'));
        // // eslint-disable-next-line no-undef
        // const gender = Number(localStorage.getItem('gender'));
        // const evNumber = Math.random().toString(36).substr(2, 10);
        // const eventID = `${EVENTS.COMPLETE_REGISTRATION}.${evNumber}`;
        // const fbp = getFBP();
        // const fbc = getFBC();

        // // Create customer
        // const request = await createCustomer(
        //   email,
        //   dob,
        //   '',
        //   gender,
        //   eventID,
        //   fbp,
        //   fbc,
        //   searchParams,
        // );
        // setCustomer(request.data.customer);
        // setIsLoading(false);

        // try {
        //   const external_id = request.data.customer.id;
        //   const dobString = moment.utc(dob).format('DD.MM.YYYY');
        //   const advancedMatching = {
        //     em: email,
        //     external_id,
        //     db: dobString.substring(6, 10) + dobString.substring(3, 5) + dobString.substring(0, 2),
        //     ge: gender === 0 ? 'm' : 'f',
        //     country: 'us',
        //   };
        //   const options = {
        //     autoConfig: true,
        //     debug: false,
        //   };
        //   fbq('init', PIXEL_ID, advancedMatching as any, options);

        //   fbq(
        //     'track',
        //     'CompleteRegistration',
        //     {},
        //     {
        //       eventID,
        //     },
        //   );

        //   trackEvent(ANALYTICS_EVENTS.COMPLETEREGISTRATION);
        //   mixpanel.alias(external_id);
        //   mixpanel.people.set({
        //     $email: email,
        //   });
        // } finally {
        // }
        // gotoNext([email]);
      } else {
        setIsLoading(false);
        setError(true);
      }
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <CustomText style={styles.title} bold size={15}>
        {format(step.screen.title, false)}
      </CustomText>

      <CustomTextField
        error={error}
        placeholder="Your email"
        errorText="We didn't recognise this email address."
        value={email}
        onValueChange={onValueChange}
      />

      <CustomText style={styles.subTitle} medium size={11}>
        {step.screen.additional}
      </CustomText>

      <CustomText textAlign="center" medium style={styles.legalText} size={11} noHTML>
        By clicking "Next" below you agree to Spirit's{' '}
        <CustomText noHTML medium style={styles.linkText} onPress={onEULAPressed}>
          EULA
        </CustomText>{' '}
        and{' '}
        <CustomText noHTML medium style={styles.linkText} onPress={onPrivacyPressed}>
          Privacy Notice
        </CustomText>
        .
      </CustomText>

      <View style={styles.buttonContainer}>
        <Button
          style={styles.button}
          disabled={email === '' || isLoading}
          title={'Next'}
          loading={isLoading}
          onPress={onNext}
        />
      </View>
    </View>
  );
};
