import React, { forwardRef, FunctionComponent } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { COLORS } from '../../utils/Theme';

const useMuiStyles = makeStyles(() => {
  return {
    root: {
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.DATE_SELECT_PRIMARY,
        borderRadius: 16,
      },
      '&.MuiFormControl-root .MuiOutlinedInput-notchedOutline': {
        borderColor: COLORS.DATE_SELECT_PRIMARY,
        borderRadius: 16,
      },
    },
  };
});

const useMuiInputStyles = makeStyles(() => {
  return {
    root: {
      color: COLORS.WHITE,
    },
  };
});

const useMuiHelperTextStyles = makeStyles(() => {
  return {
    root: {
      color: COLORS.WHITE,
    },
  };
});

interface ICustomTextFieldProps {
  ref?: any;
  error?: boolean;
  errorText?: string;
  value: string;
  placeholder?: string;
  onValueChange: (newValue: string) => void;
}

export const CustomTextField: FunctionComponent<ICustomTextFieldProps> = forwardRef(
  (props, ref) => {
    const { error, value, onValueChange, errorText, placeholder } = props;
    const muiStyles = useMuiStyles();
    const muiInputStyles = useMuiInputStyles();
    const muiHelperTextStyles = useMuiHelperTextStyles();

    return (
      <TextField
        fullWidth
        placeholder={placeholder}
        InputProps={{ classes: muiInputStyles }}
        FormHelperTextProps={{ classes: muiHelperTextStyles }}
        classes={muiStyles}
        variant="outlined"
        inputRef={ref}
        error={error}
        helperText={error && errorText}
        value={value}
        onChange={(ev) => {
          if (ev && ev.target && ev.target.value !== undefined) {
            onValueChange(ev.target.value);
          }
        }}
      />
    );
  },
);

CustomTextField.defaultProps = {
  errorText: 'Please choose your birthplace from the drop-down list.',
  error: false,
};
