import React, { FunctionComponent, useEffect } from 'react';
import { View } from 'react-native';
import { useOnboarding } from '../../context/OnboardingContext';

import { EContentType } from '../../enums/EContentType';
import { IStep } from '../../interfaces/IStep';
import { FormDateStep } from './FormDateStep';
import { FormEmailStep } from './FormEmailStep';
import { FormInfoStep } from './FormInfoStep';
import { FormPlaceStep } from './FormPlaceStep';
import { FormPollStep } from './FormPollStep';
import { FormSpiritStep } from './FormSpiritStep';
import { FormTimebarStep } from './FormTimebarStep';
import { FormTimeStep } from './FormTimeStep';
import { UnknownStep } from './UnknownStep';

const useStyles = (): any => {
  return {
    container: {
      marginTop: 20,
      flex: 1,
    },
  };
};

interface IFormStepProps {
  step: IStep;
}

export const FormStep: FunctionComponent<IFormStepProps> = (props) => {
  const { step } = props;
  const styles = useStyles();
  const { setHeaderVisible } = useOnboarding();

  useEffect(() => {
    // Type POLL handles the progress visibility on its own
    if (step.screen.contentType !== EContentType.POLL) {
      setHeaderVisible(
        step.screen.contentType !== EContentType.INFO &&
          step.screen.contentType !== EContentType.TIMEBAR &&
          step.screen.contentType !== EContentType.EMAIL &&
          step.screen.contentType !== EContentType.SPIRIT,
      );
    }
  }, [step]);

  const renderStep = () => {
    if (step.screen.contentType === EContentType.POLL) {
      return <FormPollStep step={step} />;
    } else if (step.screen.contentType === EContentType.INFO) {
      return <FormInfoStep step={step} />;
    } else if (step.screen.contentType === EContentType.DATE) {
      return <FormDateStep step={step} />;
    } else if (step.screen.contentType === EContentType.TIME) {
      return <FormTimeStep step={step} />;
    } else if (step.screen.contentType === EContentType.PLACE) {
      return <FormPlaceStep step={step} />;
    } else if (step.screen.contentType === EContentType.SPIRIT) {
      return <FormSpiritStep step={step} />;
    } else if (step.screen.contentType === EContentType.EMAIL) {
      return <FormEmailStep step={step} />;
    } else if (step.screen.contentType === EContentType.TIMEBAR) {
      return <FormTimebarStep step={step} />;
    } else {
      return <UnknownStep step={step} />;
    }
  };

  return <View style={styles.container}>{renderStep()}</View>;
};
