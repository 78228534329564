import React, { FunctionComponent, useState } from 'react';
import {
  GestureResponderEvent,
  Image,
  LayoutChangeEvent,
  TouchableOpacity,
  View,
} from 'react-native';
import { CustomText } from './CustomText';
import { useLayout } from '../context/LayoutContext';
import { COLORS } from '../utils/Theme';

const useStyles = (height: number): any => {
  const { WSPACING } = useLayout();

  const imageSize = 20;

  return {
    image: {
      width: imageSize,
      height: imageSize,
    },
    imageContainer: {
      position: 'absolute',
      right: WSPACING(0.75),
      top: height / 2 - imageSize / 2,
      alignItems: 'center',
    },
    buttonContentContainer: {
      paddingLeft: 20,
      paddingRight: imageSize + 20,
      paddingVertical: 20,
    },

    button: {
      borderRadius: 12,
    },
    text: {
      letterSpacing: 0.4,
    },
    buttonActive: {
      button: { backgroundColor: COLORS.BUTTON_PRIMARY_END },
      text: { color: COLORS.WHITE },
    },
    buttonInactive: {
      button: { backgroundColor: COLORS.BUTTON_SECONDARY },
      text: { color: COLORS.WHITE_TRANSPARENT },
    },
    hidden: {
      opacity: 0,
    },
  };
};

interface IInputButtonProps {
  title: string;
  rightIcon: any;
  active?: boolean;
  onPress: (event: GestureResponderEvent) => void;
}

export const InputButton: FunctionComponent<IInputButtonProps> = (props) => {
  const { title, rightIcon, active = false, onPress } = props;
  const [height, setHeight] = useState<number>(0);
  const styles = useStyles(height);

  const onLayoutChanged = (e: LayoutChangeEvent) => {
    if (e && e.nativeEvent && e.nativeEvent.layout) {
      setHeight(e.nativeEvent.layout.height);
    }
  };

  return (
    <TouchableOpacity
      style={[styles.button, active ? styles.buttonActive.button : styles.buttonInactive.button]}
      onPress={onPress}
      onLayout={onLayoutChanged}>
      <View style={styles.buttonContentContainer}>
        <CustomText
          medium
          size={14}
          style={[styles.text, active ? styles.buttonActive.text : styles.buttonInactive.text]}>
          {title}
        </CustomText>
      </View>
      <View style={[styles.imageContainer, !active && styles.hidden]}>
        <Image source={rightIcon} style={styles.image} />
      </View>
    </TouchableOpacity>
  );
};
