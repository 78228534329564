import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { useOnboarding } from '../../context/OnboardingContext';
import { IStep } from '../../interfaces/IStep';
import { Button } from '../Button';
import { CustomText } from '../CustomText';

const useStyles = (): any => {
  return {};
};

interface IUnknownStepProps {
  step: IStep;
}

export const UnknownStep: FunctionComponent<IUnknownStepProps> = (props) => {
  const styles = useStyles();
  const { step } = props;
  const { gotoNext } = useOnboarding();

  const onNext = () => {
    gotoNext();
  };

  return (
    <View>
      <CustomText>{JSON.stringify(step)}</CustomText>
      <Button title={'Next'} onPress={onNext} />
    </View>
  );
};
