import moment from 'moment';
import { COLORS } from './Theme';

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
}

const Ue = /\[([A-Z]+)=(\w+)\]/g;
const De = /\*(.+)\*/g;
const Be = /\-(.+)\-/g;

export function format(e, t = !0) {
  let a,
    n = e || '';
  for (; (a = Ue.exec(e)) !== null; ) {
    const [e, l, r] = a,
      o = a.index === 0;
    let i = '';
    switch (l) {
      case 'GET': {
        const e = Getters[r == null ? void 0 : r.toUpperCase()];
        i = t ? `<span class="tag">${o ? e.slice(0, 1).toUpperCase() + e.slice(1) : e}</span>` : e;
        break;
      }
      case 'GROUP': {
        const { name: e, color: a } = getGroupInfo(r);
        i = e ? (t ? `<span class="text-bold" style="color: ${a}">${e}</span>` : e) : '';
        break;
      }
    }
    n = (n || '').replace(e, i);
  }
  return n
    .replace(/[\r\n]/g, '<br/>')
    .replace(De, '<span class="accent">$1</span>')
    .replace(Be, '<i>$1</i>');
}

export enum ue {
  BIRTH_DATE = 'date-of-birth',
  CUSP_VALUE_1 = 'cusp_value_1',
  CUSP_VALUE_2 = 'cusp_value_2',
  DECISION = 'PatternsDecision',
  GENDER = 'gender',
  GOAL = 'goal',
  PARENT = 'ProfileParent',
  PARTNER_BIRTH_DATE = 'PartnerDateOfBirth',
  PARTNER_ZODIAC = 'partnerZodiac',
  RELATIONSHIP_STATUS = 'relationship-status',
  RESERVATION_DATE = 'reservation-date',
  RESERVATION_TIME = 'reservation-time',
  SHOW_PAYMENT = 'show-payment-methods',
  ZODIAC = 'zodiac',
}

export const Storage = {
  getValue: (key: ue) => {
    return localStorage.getItem(key);
  },
  setValue: (key: any, value: any) => {
    localStorage.setItem(key, value);
  },
};

function Ee(param: string) {
  return param;
}

const enum ZODIAC_SIGN {
  AQUARIUS = 'Aquarius',
  ARIES = 'Aries',
  CANCER = 'Cancer',
  CAPRICORN = 'Capricorn',
  GEMINI = 'Gemini',
  LEO = 'Leo',
  LIBRA = 'Libra',
  PISCES = 'Pisces',
  SAGITTARIUS = 'Sagittarius',
  SCORPIO = 'Scorpio',
  TAURUS = 'Taurus',
  VIRGO = 'Virgo',
}

const ZODIAC_STATS = {
  Aquarius: '3,201,402',
  Aries: '4,342,403',
  Cancer: '2,865,756',
  Capricorn: '4,210,605',
  Gemini: '3,945,401',
  Leo: '3,856,501',
  Libra: '2,505,301',
  Pisces: '3,209,402',
  Sagittarius: '2,450,190',
  Scorpio: '5,240,104',
  Taurus: '4,132,607',
  Virgo: '4,452,504',
};

const PHRASES = {
  VP: 'understand themselves better',
  VP1: 'Finding the most compatible partner.',
  VP2: '',
  VP3: 'understand yourself better and find your perfect partner',
};

const ge = {
    [ZODIAC_SIGN.ARIES]: ['03/21', '04/19'],
    [ZODIAC_SIGN.TAURUS]: ['04/20', '05/20'],
    [ZODIAC_SIGN.GEMINI]: ['05/21', '06/20'],
    [ZODIAC_SIGN.CANCER]: ['06/21', '07/22'],
    [ZODIAC_SIGN.LEO]: ['07/23', '08/22'],
    [ZODIAC_SIGN.VIRGO]: ['08/23', '09/22'],
    [ZODIAC_SIGN.LIBRA]: ['09/23', '10/22'],
    [ZODIAC_SIGN.SCORPIO]: ['10/23', '11/21'],
    [ZODIAC_SIGN.SAGITTARIUS]: ['11/22', '12/21'],
    [ZODIAC_SIGN.CAPRICORN]: ['12/22', '01/19'],
    [ZODIAC_SIGN.AQUARIUS]: ['01/20', '02/18'],
    [ZODIAC_SIGN.PISCES]: ['02/19', '03/20'],
  },
  pe = {
    [ZODIAC_SIGN.ARIES]: '35/49/16',
    [ZODIAC_SIGN.TAURUS]: '31/48/21',
    [ZODIAC_SIGN.GEMINI]: '48/33/19',
    [ZODIAC_SIGN.CANCER]: '39/44/17',
    [ZODIAC_SIGN.LEO]: '55/30/15',
    [ZODIAC_SIGN.VIRGO]: '40/38/22',
    [ZODIAC_SIGN.LIBRA]: '46/36/18',
    [ZODIAC_SIGN.SCORPIO]: '52/29/19',
    [ZODIAC_SIGN.SAGITTARIUS]: '53/29/18',
    [ZODIAC_SIGN.CAPRICORN]: '32/50/18',
    [ZODIAC_SIGN.AQUARIUS]: '55/27/18',
    [ZODIAC_SIGN.PISCES]: '32/54/14',
  },
  fe = {};

const DateHelper = {
  format: 'mm/dd/yyyy',
  parseDate: (date: any) => {
    const [t, a, n] = date.split('/');
    return [a, t, n];
  },
};

export function calculateAge(date: any) {
  date = Number(date);
  if (!date) {
    return -1;
  }
  const t = DateHelper,
    [, , a] = t.parseDate(moment.utc(date).format(t.format.toUpperCase())),
    n = new Date().getFullYear() - +a;
  return +`${Math.floor(n / 10)}0`;
}

export function calculateZodiacSign(e) {
  e = Number(e);
  if (!e) {
    return '';
  }
  const t = fe[e == null ? void 0 : e.toString()];
  if (t) {
    return t;
  }

  const a = new Date(e),
    n = a.getMonth() + 1,
    l = a.getDate(),
    r = Object.entries(ge).find(([e, t]) => {
      const [a, r] = t[0].split('/').map((e) => +e),
        [o, i] = t[1].split('/').map((e) => +e);
      return (
        Math.abs(i - l) <= 2 && o === n
          ? (Storage.setValue('cusp_1', JSON.stringify(!1)),
            Storage.setValue('cusp_2', JSON.stringify(!0)),
            Storage.setValue(
              'cusp_value_2',
              JSON.stringify(
                Object.keys(ge)[
                  Object.keys(ge).indexOf(e) === 11 ? 0 : Object.keys(ge).indexOf(e) + 1
                ],
              ),
            ))
          : Storage.setValue('cusp_2', JSON.stringify(!1)),
        Math.abs(r - l) <= 2 && a === n
          ? (Storage.setValue('cusp_2', JSON.stringify(!1)),
            Storage.setValue('cusp_1', JSON.stringify(!0)),
            Storage.setValue(
              'cusp_value_1',
              JSON.stringify(
                Object.keys(ge)[
                  Object.keys(ge).indexOf(e) === 0 ? 11 : Object.keys(ge).indexOf(e) - 1
                ],
              ),
            ))
          : Storage.setValue('cusp_1', JSON.stringify(!1)),
        a === n ? r <= l : o === n && i >= l
      );
    });
  return (fe[e == null ? void 0 : e.toString()] = r ? r[0] : '');
}

var Ie = {
  0: {
    0: 'find their perfect partner',
    1: 'understand themselves better',
    default: 'understand themselves better and find their perfect partner',
  },
  1: {
    default: 'improve their relationship',
  },
};
var Pe = {
  0: {
    0: 'find your perfect partner',
    1: 'understand yourself better and find your perfect partner',
    default: 'understand yourself better and find your perfect partner',
  },
  1: {
    default: 'improve your relationship',
  },
};

const Getters = {
  get GENDER() {
    var a = '';
    const t = Storage.getValue(ue.GENDER);
    switch (t) {
      case '0':
        a = 'men';
        break;
      case '1':
        a = 'women';
    }
    return a;
  },
  get ZODIAC_NUMBER() {
    return (function (e) {
      const t = calculateZodiacSign(Number(e));
      return ZODIAC_STATS[t] || '';
    })(Storage.getValue(ue.BIRTH_DATE));
  },
  get ZODIAC() {
    const e = Storage.getValue(ue.BIRTH_DATE);
    return `<strong class="accent">${
      Storage.getValue(ue.ZODIAC) || calculateZodiacSign(Number(e))
    }</strong>`;
  },
  get PARTNER_ZODIAC() {
    const e = Storage.getValue(ue.PARTNER_BIRTH_DATE);
    return `<strong class="accent">${
      Storage.getValue(ue.PARTNER_ZODIAC) || calculateZodiacSign(Number(e))
    }</strong>`;
  },
  get ZODIAC_NORMAL() {
    const e = Storage.getValue(ue.BIRTH_DATE);
    return Storage.getValue(ue.ZODIAC) || calculateZodiacSign(Number(e));
  },
  get PARTNER_ZODIAC_NORMAL() {
    const e = Storage.getValue(ue.PARTNER_BIRTH_DATE);
    return Storage.getValue(ue.PARTNER_ZODIAC) || calculateZodiacSign(Number(e));
  },
  get AND_PARTNER() {
    const e = Storage.getValue(ue.RELATIONSHIP_STATUS);
    return e && e.toLowerCase() !== 'single' ? ' and your partner' : '';
  },
  get VP() {
    const e = Storage.getValue(ue.RELATIONSHIP_STATUS),
      t = Storage.getValue(ue.GOAL);
    if (e === null || !t === null) {
      return '';
    }
    const a = Ie[Number(e)];
    return (a && (a[Number(t)] || a.default)) || '';
  },
  get VP3() {
    const e = Storage.getValue(ue.RELATIONSHIP_STATUS),
      t = Storage.getValue(ue.GOAL);
    if (e === null || !t === null) {
      return '';
    }
    const a = Pe[Number(e)];
    return (a && (a[Number(t)] || a.default)) || '';
  },
  get VP1() {
    const e = Storage.getValue(ue.RELATIONSHIP_STATUS);
    if (e === null) {
      return '';
    }
    switch (e) {
      case '0':
        return 'Finding the most compatible partner.';
      case '1':
        return 'Improving your relationship with your partner';
    }
    return '';
  },
  get VP2() {
    const e = Storage.getValue(ue.RELATIONSHIP_STATUS);
    if (e === null) {
      return '';
    }
    switch (e) {
      case '0':
        return '';
      case '1':
        return 'and your loved one';
    }
    return '';
  },
  get AGE_GROUP() {
    return (function (e) {
      const t = calculateAge(e);
      return t < 20 ? 'under 20' : `in their ${t}s`;
    })(Storage.getValue(ue.BIRTH_DATE));
  },
  get KIDS() {
    const e = (Storage.getValue(ue.PARENT) || '') == 0;
    return e || Storage.getValue('relationship-status') != 0
      ? `who have${e ? ' ' : ' no '}children`
      : '';
  },
  get HAS_KIDS() {
    var e;
    const t = Storage.getValue(ue.PARENT) || '',
      a = (e = Storage.getValue(ue.GENDER)) == null ? void 0 : e.toLowerCase();
    return t.toLowerCase() === 'yes'
      ? a === 'male'
        ? ' and my daughter was'
        : ' and my son was'
      : '';
  },
  get DECISION() {
    const e = Storage.getValue(ue.DECISION);
    return e;
  },
  get HEAD_HEART_BOTH() {
    var e;
    const t = Storage.getValue(ue.BIRTH_DATE),
      a = Getters.DECISION,
      n = calculateZodiacSign(Number(t)),
      [l, r, o] = (e = pe[n] || '') == null ? void 0 : e.split('/');
    switch (Number(a)) {
      case 0:
        return r || 52;
      case 1:
        return l || 52;
    }
    return o || 52;
  },
  get TRIAL_END_DATE() {
    const e = new Date();
    e.setTime(e.getTime() + 6048e5);
    return `${e.getMonth() + 1}/${e.getDate()}/${e.getFullYear()}`;
  },
  get ZODIACS_MATCH() {
    return 65;
  },
};

export const customAnimatedSequence = (animations: any, onStepStart: any) => {
  let current = 0;
  return {
    start: (callback: any) => {
      const onComplete = (result: any) => {
        if (!result.finished) {
          callback && callback(result);
          return;
        }

        current += 1;

        if (current === animations.length) {
          callback && callback(result);
          return;
        }

        onStepStart && onStepStart({ current });
        animations[current].start(onComplete);
      };

      if (animations.length === 0) {
        callback && callback({ finished: true });
      } else {
        onStepStart && onStepStart({ current });
        animations[current].start(onComplete);
      }
    },

    stop: () => {
      if (current < animations.length) {
        animations[current].stop();
      }
    },

    reset: () => {
      animations.forEach((animation: any, idx: number) => {
        if (idx <= current) {
          animation.reset();
        }
      });
      current = 0;
    },

    _startNativeLoop: () => {
      throw new Error(
        'Loops run using the native driver cannot contain Animated.sequence animations',
      );
    },

    _isUsingNativeDriver: () => false,
  };
};

export const getGroupInfo = (group: number) => {
  let name = '';
  let color = 'white';

  // eslint-disable-next-line eqeqeq
  if (group == 1) {
    name = 'Your Profile';
    color = COLORS.GROUP_1;
  } else {
    const relationShipStatus = Number(Storage.getValue(ue.RELATIONSHIP_STATUS));
    // eslint-disable-next-line eqeqeq
    if (group == 2) {
      name = relationShipStatus === 0 ? 'Personality Traits' : 'Your Partner’s Profile';
      color = COLORS.GROUP_2;
      // eslint-disable-next-line eqeqeq
    } else if (group == 3) {
      name =
        relationShipStatus === 0 ? 'Relationship Pattern' : 'Relationship & Personality Patterns';
      color = COLORS.GROUP_3;
    }
  }

  return { name, color };
};
