import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { COLORS } from '../../../utils/Theme';
import { CustomText } from '../../CustomText';

const useStyles = (): any => {
  return {
    container: {
      marginVertical: 25,
      borderRadius: 10,
      borderStyle: 'solid',
      borderColor: COLORS.SPECIAL_OFFER,
      borderWidth: 1,
      paddingHorizontal: 10,
      paddingVertical: 7,
    },
  };
};

interface ISpecialOfferProps {}

export const SpecialOffer: FunctionComponent<ISpecialOfferProps> = (props) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <CustomText size={13} bold color={COLORS.SPECIAL_OFFER}>
        Special Offer
      </CustomText>
    </View>
  );
};
